import { useState, useEffect } from "react";
import api from "@services/api";
import toast from "react-hot-toast";

import EditProduct from "./editProduct";
import CreateProduct from "@components/modals/createProduct";

import ActionsMenu from "@components/utils/ActionsMenu";
import TruncatedText from "@components/utils/TruncatedText";
import { SearchBar } from "../../components/utils/SearchBar";
import { Paginator } from "@components/utils/Pagination";

import { currencyToStr } from "@utils";
import categories from "@data/categories";

import { useTranslation } from "react-i18next";
import { Mixpanel } from "../../services/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";
import { useSelector } from "react-redux";

const List = () => {
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const [filter, setFilter] = useState({ search: "", page: 1, per_page: 10 });
  const [total, setTotal] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  const { organization } = useSelector((state) => state.Auth);

  useEffect(() => {
    if (!organization) return;
    get();
  }, [filter, organization]);

  useEffect(() => {
    Mixpanel.track(MIXPANEL_EVENTS.page_view_products);
  }, []);

  async function get() {
    const { data } = await api.post("/product/search", {
      per_page: filter.per_page,
      page: filter.page,
      search: filter.search,
      OrganizationId: organization._id,
    });
    setProducts(data.products);
    setTotal(data.total);
  }

  async function handleDelete(id) {
    const confirm = window.confirm(t("confirm_message"));
    if (!confirm) return;
    await api.remove(`/product/${id}`);
    toast.success(t("toast.sucess.removed"));
    get();
  }

  return (
    <div className="mx-auto">
      <div className="py-6 px-6">
        <div className="flex flex-col bg-white rounded-xl p-6">
          <div className="flex justify-between items-start">
            <div>
              <h1 className="text-2xl font-bold">{t("products")}</h1>
              <p className="mt-2 text-slate-600">{t("products.intro")}</p>
            </div>
            <div className="flex gap-2">
              <button className="btn-primary focus:bg-blue float-right mb-2"> {t("import_from_csv")} </button>
              <button
                className="btn-primary focus:bg-blue float-right mb-2"
                onClick={() => {
                  setShowModal(true);
                }}>
                {t("products.create_a_product")}
              </button>
            </div>
          </div>
          <EditProduct product={selectedProduct} setProduct={() => setSelectedProduct(null)} onSave={get} />
          <div className="flex flex-col space-y-5 mt-8">
            <SearchBar search={filter.search} setFilter={setFilter} />
            <Table
              products={products}
              onEdit={(product) => {
                setSelectedProduct(product);
              }}
              onDelete={handleDelete}
            />
            <Paginator page={filter.page} setPage={(p) => setFilter({ ...filter, page: p })} last={Math.ceil(total / filter.per_page)} />
          </div>
        </div>
      </div>
      <CreateProduct
        onCreated={(el) => {
          get();
          setSelectedProduct(el);
        }}
        open={showModal}
        onClose={() => setShowModal(false)}
      />
    </div>
  );
};

const Table = ({ products, onEdit, onDelete }) => {
  const { t } = useTranslation();
  return (
    <div className="overflow-x-auto flow-root min-w-full align-middle overflow-hidden rounded-lg border">
      <table className="min-w-full">
        <thead className="border-b">
          <tr>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-bold border-r uppercase">
              {t("name")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-500 uppercase">
              {t("type")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-500 uppercase">
              {t("reference")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-500 uppercase">
              {t("quantity")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-500 uppercase">
              {t("unit")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-500 uppercase">
              {t("description")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-500 uppercase">
              {t("category")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-500 uppercase">
              {t("unit_price")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-500 uppercase">
              {t("tax_type")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-500 uppercase">
              {t("actions")}
            </th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {products.map((product) => (
            <tr key={product._id}>
              <td className="whitespace-nowrap px-2 py-2 text-sm border-r">
                <span
                  onClick={() => {
                    onEdit(product);
                    Mixpanel.track(MIXPANEL_EVENTS.page_view_products_edit);
                  }}
                  className="cursor-pointer font-bold underline">
                  <TruncatedText str={product.name} />
                </span>
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 uppercase">
                {product.type === "PRODUCT" ? t("product") : product.type === "SERVICE" ? t("service") : "-"}
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{product.reference || "-"}</td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{product.quantity || "-"}</td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{product.unit || "-"}</td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{<TruncatedText str={product.description} />}</td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 text-left">
                {product.category ? product.category + " - " + t(`transactions.${product.category}`) : null}
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                {product.price || "-"} {currencyToStr(product.currency)}
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{product.Tax?.name || "-"}</td>
              <td className="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                <ActionsMenu
                  actions={[
                    {
                      label: t("edit"),
                      callback: () => {
                        onEdit(product);
                        Mixpanel.track(MIXPANEL_EVENTS.page_view_products_edit);
                      },
                    },
                    { label: t("delete"), callback: () => onDelete(product._id) },
                  ]}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default List;
