import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import API from "../../services/api";
import { toast } from "react-hot-toast";
import { setOrganization } from "../../redux/auth/actions";

const Categories = () => {
  const { t } = useTranslation();
  const organization = useSelector((state) => state.Auth.organization);
  const dispatch = useDispatch();
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getSelectedCategories = (categoryList) => {
    const categories = categoryList.map((category) => category.id);
    if (!organization?.accountingCodes) return categories;

    return categories.filter((category) => !organization.accountingCodes.includes(category));
  };

  const handleChange = async (categoryId) => {
    try {
      setIsLoading(true);
      const newSelected = selectedCategory.includes(categoryId) ? selectedCategory.filter((id) => id !== categoryId) : [...selectedCategory, categoryId];
      const uncheckedCategories = t("category_list", { returnObjects: true })
        .map((c) => c.id)
        .filter((id) => !newSelected.includes(id));
      const { data } = await API.put(`/organization/${organization._id}`, {
        accountingCodes: uncheckedCategories,
      });
      dispatch(setOrganization(data));
      setSelectedCategory(newSelected);
      toast.success("Categories updated successfully");
    } catch (e) {
      toast.error("Error updating categories");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectAll = async () => {
    try {
      setIsLoading(true);
      const allCategories = t("category_list", { returnObjects: true }).map((c) => c.id);
      const newSelected = selectedCategory.length === allCategories.length ? [] : allCategories;
      const { data } = await API.put(`/organization/${organization._id}`, {
        accountingCodes: allCategories.filter((id) => !newSelected.includes(id)),
      });
      dispatch(setOrganization(data));
      setSelectedCategory(newSelected);
      toast.success("Categories updated successfully");
    } catch (e) {
      toast.error("Error updating categories");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const initialSelected = getSelectedCategories(t("category_list", { returnObjects: true }), organization?.accountingCodes);
    setSelectedCategory(initialSelected);
  }, [organization]);

  return (
    <div className="mx-auto">
      <div className="flex flex-col bg-white rounded-xl p-6">
        <div className="mb-4">
          <h1 className="text-2xl font-bold mb-2">{t("categories")}</h1>
          <p className="text-gray-600">{t("categories.description")}</p>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-200 rounded-lg">
            <thead>
              <tr className="bg-gray-50">
                <th className="px-4 py-2 text-left text-sm font-medium text-gray-700 border-b">ID</th>
                <th className="px-4 py-2 text-left text-sm font-medium text-gray-700 border-b">{t("name")}</th>
                <th className="px-4 py-2 text-left text-sm font-medium text-gray-700 border-b">{t("group")}</th>
                <th className="px-4 py-2 text-left text-sm font-medium text-gray-700 border-b">{t("description")}</th>
                <th className="px-4 py-2 text-right text-sm font-medium text-gray-700 border-b">
                  <button
                    onClick={handleSelectAll}
                    className="px-4 py-2 text-xs font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 w-fit whitespace-nowrap"
                    disabled={isLoading}>
                    {selectedCategory.length === t("category_list", { returnObjects: true }).length ? t("unselect_all") : t("select_all")}
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              {t("category_list", { returnObjects: true }).map((category) => (
                <tr key={category.id} className="hover:bg-gray-50 transition-colors">
                  <td className="px-4 py-3 bg-gray-100 text-gray-800 text-sm border-b">{category.id}</td>
                  <td className="px-4 py-3 text-gray-900 font-semibold text-sm border-b">{category.name}</td>
                  <td className="px-4 py-3 text-gray-800 text-sm font-medium border-b">{category.group}</td>
                  <td className="px-4 py-3 text-gray-700 text-sm border-b">{category.description}</td>
                  <td className="px-4 py-3 text-right border-b">
                    <label className="relative inline-flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        checked={selectedCategory.includes(category.id)}
                        onChange={() => handleChange(category.id)}
                        className="sr-only peer"
                        disabled={isLoading}
                      />
                      <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600" />
                    </label>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Categories;
