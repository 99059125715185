import React from "react";
import { useTranslation } from "react-i18next";

const Index = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg className="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <div className="ml-3">
            <p className="text-sm text-yellow-700">{t("copilot.beta_warning")}</p>
          </div>
        </div>
      </div>
      <div className="px-24 mx-auto">
        <div className="mt-4 w-full h-[70vh] rounded-lg overflow-hidden shadow-md">
          <iframe
            src="https://copilotstudio.microsoft.com/environments/Default-aa9bf2e6-9fad-4272-97ce-2e074c051a78/bots/cr228_agentCodeComptable/webchat?__version__=2"
            className="w-full h-full border-0"
            title="Azure Copilot Chat"
          />
        </div>
      </div>
      <Categories />
    </>
  );
};

const Categories = () => {
  const { t } = useTranslation();

  return (
    <div className="px-24 mt-4">
      <div className="flex flex-col bg-white rounded-xl p-6 shadow-md">
        <h1 className="text-2xl font-bold mb-4">{t("categories")}</h1>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-200 rounded-lg">
            <thead>
              <tr className="bg-gray-50">
                <th className="px-4 py-2 text-left text-sm font-medium text-gray-700 border-b">ID</th>
                <th className="px-4 py-2 text-left text-sm font-medium text-gray-700 border-b">{t("name")}</th>
                <th className="px-4 py-2 text-left text-sm font-medium text-gray-700 border-b">{t("group")}</th>
                <th className="px-4 py-2 text-left text-sm font-medium text-gray-700 border-b">{t("description")}</th>
              </tr>
            </thead>
            <tbody>
              {t("category_list", { returnObjects: true }).map((category) => (
                <tr key={category.id} className="hover:bg-gray-50 transition-colors">
                  <td className="px-4 py-3 bg-gray-100 text-gray-800 text-sm border-b">{category.id}</td>
                  <td className="px-4 py-3 text-gray-900 font-semibold text-sm border-b">{category.name}</td>
                  <td className="px-4 py-3 text-gray-800 text-sm font-medium border-b">{category.group}</td>
                  <td className="px-4 py-3 text-gray-700 text-sm border-b">{category.description}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Index;
