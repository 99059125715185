import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { FaFileInvoice } from "react-icons/fa";
import useDevice from "@hooks/useDevice";
import { HiHome, HiOutlineIdentification, HiOutlineSparkles } from "react-icons/hi2";
import { SiGoogleanalytics } from "react-icons/si";
import { FaPiggyBank } from "react-icons/fa";
import { LuArrowDownUp } from "react-icons/lu";
import { classNames } from "@utils";
import { AiOutlineDatabase } from "react-icons/ai";
import { MdKeyboardArrowUp } from "react-icons/md";
import { useTranslation } from "react-i18next";
import Logo from "@assets/logo-full.svg";
import ProgressBar from "./ProgressBar";
import { Mixpanel } from "../services/mixpanel";
import { MIXPANEL_EVENTS } from "../utils/mixpanelEvents";
import TrustBox from "./Trustbox";
import api from "../services/api";
import toast from "react-hot-toast";

const Drawer = ({ open, setModalPricingOpen }) => {
  const device = useDevice();
  const { pathname } = useLocation();

  if (pathname.indexOf("/auth") !== -1) return null;
  if (device === "mobile") return <DrawerMobile open={open} />;
  else return <DrawerDesktop setModalPricingOpen={setModalPricingOpen} />;
};

const DrawerDesktop = ({ setModalPricingOpen }) => {
  const user = useSelector((state) => state.Auth.user);

  const onUpdate = () => {
    try {
      const { ok, data } = api.put(`/user/${user._id}`, { NpsTrustpilotClickAt: new Date() });
      if (!ok) console.log("Error updating NpsTrustpilotClickAt");
      Mixpanel.track(MIXPANEL_EVENTS.btn_review_truspilot);
      toast.success("Thank you for your review!");
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="sticky flex flex-shrink-0 overflow-y-auto h-screen inset-y-0 bg-white">
      <ul className="flex flex-col list-none px-3 py-3 z-10 border-r border-[#a0a6b124] space-y-1 translate-x-0 duration-200">
        <img src={Logo} alt="logo" className="w-24 h-12" />
        <Menu />
        {user.NpsDoneAt && user.NpsReferralGrade >= 8 && !user.NpsTrustpilotClickAt && (
          <div className="pt-3">
            <TrustBox handleClick={onUpdate} />
          </div>
        )}
        <div className="flex-1" />
        <FreeTrialIndicator
          onClick={() => {
            Mixpanel.track(MIXPANEL_EVENTS.btn_subscription);
            setModalPricingOpen(true);
          }}
        />
      </ul>
    </div>
  );
};

const DrawerMobile = ({ open }) => {
  const ref = React.useRef(null);
  return (
    <div className="flex-shrink-0 overflow-y-auto" ref={ref}>
      <ul className={`list-none z-10 ${open ? "w-40 px-3" : "w-0"} border-r border-[#a0a6b124] space-y-2 duration-200`} open={open}>
        <Menu />
      </ul>
    </div>
  );
};

const FreeTrialIndicator = ({ onClick }) => {
  const { t } = useTranslation();

  const organization = useSelector((state) => state.Auth.organization);
  const remainingDays = Math.ceil((new Date(organization?.freeTrialEndAt) - new Date()) / (1000 * 60 * 60 * 24));

  const freetrialstartat = new Date(organization?.freeTrialStartAt);
  const freetrialendat = new Date(organization?.freeTrialEndAt);
  const today = new Date();
  const total = freetrialendat - freetrialstartat;
  const current = today - freetrialstartat;
  const percentage = Math.floor((current / total) * 100);

  if (["premium", "lite", "business", "starter"].includes(organization?.plan)) return null;

  if (organization?.plan === "free" && remainingDays > 0)
    return (
      <button className="bg-gray-200 rounded-md p-2" onClick={onClick} target="_blank">
        <div className="mb-1 text-xs">
          <span className="font-bold">{remainingDays}</span>&nbsp;{t("free_trial_remaining")}
        </div>
        <ProgressBar percentage={percentage} />
      </button>
    );
};

const Groups = () => {
  const { t } = useTranslation();
  return [
    {
      title: t("drawer.home"),
      to: "/",
      Icon: HiHome,
    },
    {
      title: t("drawer.analytics"),
      to: "/dashboard",
      Icon: SiGoogleanalytics,
    },
    {
      title: t("drawer.copilot"),
      to: "/copilot",
      Icon: HiOutlineSparkles,
      tag: "AI",
      onClick: () => Mixpanel.track(MIXPANEL_EVENTS.btn_goto_copilot_from_navbar),
    },
    {
      title: t("drawer.transactions"),
      to: "/transactions",
      Icon: LuArrowDownUp,
    },
    {
      title: t("stripe_transactions"),
      to: "/transactions-stripe",
      Icon: LuArrowDownUp,
    },
    {
      title: t("drawer.my_integrations"),
      to: "/bank",
      Icon: FaPiggyBank,
    },
    {
      title: t("employees"),
      to: "/employees",
      Icon: HiOutlineIdentification,
    },
    {
      title: t("drawer.purchases"),
      items: [
        {
          title: t("suppliers"),
          to: "/suppliers",
          Icon: HiOutlineIdentification,
        },
        {
          title: t("purchase_invoices"),
          to: "/purchase-invoice",
          Icon: FaFileInvoice,
        },
      ],
    },
    {
      title: t("drawer.sales"),
      items: [
        {
          title: t("products"),
          to: "/products",
          Icon: AiOutlineDatabase,
        },
        {
          title: t("clients"),
          to: "/clients",
          Icon: HiOutlineIdentification,
        },
        {
          title: t("invoices"),
          to: "/invoices",
          Icon: FaFileInvoiceDollar,
        },
      ],
    },
  ];
};

const Menu = () => {
  const user = useSelector((state) => state.Auth.user);
  return (
    <>
      {["admin", "user"].includes(user.role) && (
        <>
          {Groups().map((group, i) => {
            if (group.items) {
              return <Group title={group.title} items={group.items} key={i} />;
            } else {
              return <Link key={i} {...group} />;
            }
          })}
        </>
      )}
    </>
  );
};

const Group = ({ title, items }) => {
  const [open, setOpen] = useState(true);

  return (
    <>
      <div className="flex justify-between items-center text-xs p-2 cursor-pointer" onClick={() => setOpen((e) => !e)}>
        <span>{title}</span>
        <MdKeyboardArrowUp className={`${open ? "rotate-0" : "-rotate-180"} transition-all text-base`} />
      </div>
      <ul className="ml-3 list-none ease-in-out duration-300 transform origin-top overflow-hidden" style={{ maxHeight: open ? items.length * 40 : "0px" }}>
        {items.map((item, i) => (
          <li className="my-1" key={i}>
            <Link key={i} {...item} />
          </li>
        ))}
      </ul>
    </>
  );
};

const Link = ({ Icon, title, to, onClick = () => {}, exact = false, tag }) => {
  return (
    <NavLink
      onClick={onClick}
      exact={exact.toString()}
      style={{ textDecoration: "none" }}
      className={(nav) =>
        classNames(
          nav.isActive && "text-blue-500 ease-in-out duration-200",
          "px-2 py-2 flex gap-3 items-center rounded-md hover:translate-x-1 hover:ease-in-out hover:duration-200 hover:text-blue-500 text-sm leading-6 relative",
        )
      }
      to={to}>
      <Icon />
      <span className="text-sm">{title}</span>
      {tag && <span className="absolute right-2 bg-gradient-to-r from-blue-500 to-purple-500 text-white text-xs px-2 py-0.5 rounded-full shadow-md hover:scale-105">{tag}</span>}
    </NavLink>
  );
};

export default Drawer;
