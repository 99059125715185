import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter, Route, Routes, Navigate, Outlet } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import queryString from "query-string";

import { ENVIRONMENT, SENTRY_URL } from "./config";
import { Mixpanel } from "./services/mixpanel";
import Loader from "./components/utils/Loader";
import Header from "./components/header/Header";
import Drawer from "./components/Drawer";
import ScrollToTop from "./components/utils/ScrollToTop";
import api from "./services/api";
import Hotjar from "./services/hotjar";
import { setUser } from "./redux/auth/actions";
import { setOrganization } from "./redux/auth/actions";
import { useTranslation } from "react-i18next";

import Analytics from "./scenes/analytics";
import Reset from "./scenes/auth/Reset";
import Forgot from "./scenes/auth/Forgot";
import SignIn from "./scenes/auth/SignIn";
import Signup from "./scenes/auth/Signup";
import SignupInvite from "./scenes/auth/SignupInvite";
import Checkout from "./scenes/auth/Checkout";
import ConfirmationCheckout from "./scenes/auth/ConfirmationCheckout";
import Language from "./scenes/auth/language";
import Bank from "./scenes/bank";
import ProductList from "./scenes/products";
import ClientList from "./scenes/clients";
import SupplierList from "./scenes/suppliers";
import EmployeeList from "./scenes/employees";
import Transactions from "./scenes/transactions";
import StripeTransactions from "./scenes/stripeTransactions";
import Invoices from "./scenes/invoices";
import PurchaseInvoices from "./scenes/purchaseInvoices";
import Settings from "./scenes/settings";
import MigrationXero from "./scenes/settings/migrationXero";
import Home from "@scenes/home";
import Referrals from "./scenes/referral";
import Callback from "./scenes/gocardless/callback";
import Copilot from "./scenes/copilot";
import Modal from "@components/utils/Modal";
import { MIXPANEL_EVENTS } from "./utils/mixpanelEvents";
import NPSModal from "./components/modals/NPSModal";

if (import.meta.env.PROD) {
  SENTRY_URL &&
    Sentry.init({
      dsn: SENTRY_URL,
      environment: "app",
      ignoreErrors: ["TypeError: Failed to fetch", "TypeError: Load failed", "TypeError: NetworkError when attempting to fetch resource."],
    });
}

const App = () => {
  return (
    <BrowserRouter>
      <Hotjar />
      <ScrollToTop />
      <Routes>
        <Route path="/confirmation-checkout" element={<ConfirmationCheckout />} />
        <Route element={<AuthLayout />}>
          <Route path="/auth" element={<SignIn />} />
          <Route path="/auth/signup" element={<Signup />} />
          <Route path="/auth/signup/invite" element={<SignupInvite />} />
          <Route path="/auth/forgot" element={<Forgot />} />
          <Route path="/auth/reset" element={<Reset />} />
        </Route>
        <Route element={<ProtectedLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/purchase-invoice/*" element={<PurchaseInvoices />} />
          <Route path="/invoices/*" element={<Invoices />} />
          <Route path="/suppliers/*" element={<SupplierList />} />
          <Route path="/clients/*" element={<ClientList />} />
          <Route path="/employees/*" element={<EmployeeList />} />
          <Route path="/products/*" element={<ProductList />} />
          <Route path="/transactions-stripe/*" element={<StripeTransactions />} />
          <Route path="/transactions/*" element={<Transactions />} />
          <Route path="/bank/*" element={<Bank />} />
          <Route path="/migration/xero" element={<MigrationXero />} />
          <Route path="/settings/:tab?" element={<Settings />} />
          <Route path="/dashboard" element={<Analytics />} />
          <Route path="/gocardless/oauth/callback" element={<Callback />} />
          <Route path="/referral/*" element={<Referrals />} />
          <Route path="/copilot/*" element={<Copilot />} />
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};

const ProtectedLayout = () => {
  const dispatch = useDispatch();
  const { user, organization } = useSelector((state) => state.Auth);
  const [loading, setLoading] = useState(true);
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const [modalPricingOpen, setModalPricingOpen] = useState(false);
  const { t, i18n } = useTranslation();

  const freeTrialIsEnded = organization?.plan === "free" && organization?.freeTrialEndAt && new Date(organization?.freeTrialEndAt) < new Date();

  async function fetchData() {
    try {
      const res = await api.get("/user/signin_token");
      if (!res.ok || !res.user) return setLoading(false);
      if (res.token) api.setToken(res.token);
      dispatch(setUser(res.user));
      Mixpanel.identify(res.user._id);
      Mixpanel.people.set({ ...res.user, $email: res.user.email });
      i18n.changeLanguage(res.user.language);
      Mixpanel.track(MIXPANEL_EVENTS.loadSuccess);
      const { data: organizations } = await api.post("/organization/search");
      dispatch(setOrganization(organizations[0]));
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Loader />
      </div>
    );
  }
  if (!user) return <Navigate to="/auth" replace />;

  return (
    <>
      <div className="flex">
        <NPSModal />
        <Drawer open={sideBarOpen} setModalPricingOpen={setModalPricingOpen} />
        <div className="flex-col w-full">
          <Header setSideBarOpen={setSideBarOpen} />
          <main className="w-full">
            <Outlet />
          </main>
        </div>
        <Modal isOpen={freeTrialIsEnded || modalPricingOpen} onClose={() => setModalPricingOpen(false)} innerClassName="flex w-[90vw] max-h-[calc(100vh-5rem)]">
          <div className="bg-black-20 w-full p-5">
            <Checkout />
          </div>
        </Modal>
      </div>
    </>
  );
};

const AuthLayout = () => {
  const { i18n } = useTranslation();
  const user = useSelector((state) => state.Auth.user);
  const params = queryString.parse(location.search);
  const { lang } = params;

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang.toUpperCase());
    }
  }, [lang]);

  if (user) return <Navigate to="/" replace={true} />;
  return (
    <div className="flex w-full min-h-screen">
      <div className="absolute right-0 z-10 top-0 p-2">
        <Language />
      </div>
      <Outlet />
    </div>
  );
};

export default App;
