import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { FaFileInvoice } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { IoCheckmarkCircleOutline } from "react-icons/io5";

import categories from "@data/categories.js";
import api from "@services/api";
import { Mixpanel } from "@services/mixpanel";
import { currencyToStr, getTransactionAccountName, getAccountName } from "@utils";
import { MdChevronRight } from "react-icons/md";
import StripeLogo from "@assets/stripe-logo.png";
import { MdArrowRight } from "react-icons/md";

import CreateClient from "@components/modals/createClient";
import CreateEmployee from "@components/modals/createEmployee";
import CreateSupplier from "@components/modals/createSupplier";
import { Combobox } from "@components/utils/Combobox";
import FileInputBtn from "@components/utils/FileInputBtn";
import { Select } from "@components/utils/Select";
import Loader from "../../components/utils/Loader";
import EmployeeComboBox from "./components/EmployeeComboBox";
import SupplierComboBox from "./components/SupplierComboBox";
import ClientComboBox from "./components/ClientComboBox";
import { classNames } from "@utils";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";
import { useSelector } from "react-redux";
import { FaCashRegister } from "react-icons/fa6";

const TransactionsToReconcile = ({
  loadingTransaction,
  setLoadingTransaction,
  createdByMultiple,
  setCreatedByMultiple,
  transactions,
  selectedTransactions,
  setSelectedTransactions,
  onSave,
}) => {
  const { t } = useTranslation();
  const [transactionsToReconcile, setTransactionsToReconcile] = useState(transactions);
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [clientOptions, setClientOptions] = useState([]);
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [loading, setLoading] = useState({});
  const [showCreateClient, setShowCreateClient] = useState(false);
  const [createdClient, setCreatedClient] = useState(null);
  const [createdClientIndex, setCreatedClientIndex] = useState(null);
  // const [createdByMultiple, setCreatedByMultiple] = useState(false);
  const [showCreateSupplier, setShowCreateSupplier] = useState(false);
  const [createdSupplier, setCreatedSupplier] = useState(null);
  const [createdSupplierIndex, setCreatedSupplierIndex] = useState(null);
  const [showCreateEmployee, setShowCreateEmployee] = useState(false);
  const [createdEmployee, setCreatedEmployee] = useState(null);
  const [createdEmployeeIndex, setCreatedEmployeeIndex] = useState(null);
  const [accountOptions, setAccountOptions] = useState([]);
  const { organization } = useSelector((state) => state.Auth);

  useEffect(() => {
    setTransactionsToReconcile(transactions);
  }, [transactions]);

  const getClients = async () => {
    const { data: clientRes } = await api.post(`/client/search`, { per_page: 1000 });
    setClientOptions(clientRes.clients);
  };

  const getSuppliers = async () => {
    const { data: supplierRes } = await api.post(`/supplier/search`, { per_page: 1000 });
    setSupplierOptions(supplierRes.suppliers);
  };

  const getEmployees = async () => {
    const { data: employeeRes } = await api.post(`/employee/search`, { per_page: 1000 });
    setEmployeeOptions(employeeRes.employees);
  };

  const updateTransaction = async ({ params, needTransactionsUpdate = false, successMessage = "toast.sucess.updated_sucessfully" }) => {
    setLoading((prev) => ({
      ...prev,
      [params._id]: true,
    }));
    try {
      const { ok, data } = await api.put(`/transaction/${params._id}`, params);
      if (!ok) {
        return;
      }
      if (needTransactionsUpdate) {
        const updatedTransactions = transactionsToReconcile.map((transactionToUpdate) => {
          if (transactionToUpdate._id === params._id) return { ...transactionToUpdate, ...data };
          else return transactionToUpdate;
        });
        setTransactionsToReconcile(updatedTransactions);
      }
      toast.success(t(successMessage));
      onSave();
    } catch (error) {
      console.log("error simple update", error);
      toast.error(error.code);
    } finally {
      setLoading((prev) => ({
        ...prev,
        [params._id]: false,
      }));
    }
  };

  const updateMultipleTransactions = async ({ params, needTransactionsUpdate = false, successMessage = "toast.sucess.updated_sucessfully" }) => {
    setLoadingTransaction(true);
    try {
      const { ok } = await api.post(`/transaction/multi-action/`, {
        transactionsIds: selectedTransactions.map((transaction) => transaction._id),
        ...params,
      });
      if (!ok) {
        return;
      }
      if (needTransactionsUpdate) {
        const updatedSelectedTransactions = selectedTransactions.map((transactionToUpdate) => ({
          ...transactionToUpdate,
          ...params,
        }));
        setSelectedTransactions(updatedSelectedTransactions);
      }
      toast.success(t(successMessage));
      onSave();
    } catch (error) {
      console.log("error multiple update", error);
      toast.error(error.code);
    } finally {
      setLoadingTransaction(false);
    }
  };

  useEffect(() => {
    getClients();
    getSuppliers();
    getEmployees();
  }, [transactions]);

  const getAccounts = async () => {
    const responseAccount = await api.post("/account/search", { OrganizationId: organization?._id, includePettyCash: true });
    setAccountOptions(responseAccount?.data);
  };

  useEffect(() => {
    if (!organization) return;
    getAccounts();
  }, [organization]);

  useEffect(() => {
    const updateTransactionWithNewClient = () => {
      if (createdByMultiple) {
        updateMultipleTransactions({ params: { ClientId: createdClient?._id || null }, needTransactionsUpdate: true });
      } else {
        const transaction = transactionsToReconcile[createdClientIndex];
        updateTransaction({ params: { ...transaction, ClientId: createdClient?._id || null } });
      }
      toast.success(t("toast.sucess.updated_sucessfully"));
      onSave();
    };
    if (createdClient && (createdClientIndex || createdClientIndex === 0 || createdByMultiple)) {
      updateTransactionWithNewClient();
      setCreatedByMultiple(false);
    }
  }, [createdClient]);

  useEffect(() => {
    const updateTransactionWithNewSupplier = async () => {
      if (createdByMultiple) {
        updateMultipleTransactions({ params: { SupplierId: createdSupplier?._id || null }, needTransactionsUpdate: true });
      } else {
        const transaction = transactionsToReconcile[createdSupplierIndex];
        updateTransaction({ params: { ...transaction, SupplierId: createdSupplier?._id || null } });
      }
    };
    if (createdSupplier && (createdSupplierIndex || createdSupplierIndex === 0 || createdByMultiple)) {
      updateTransactionWithNewSupplier();
      setCreatedByMultiple(false);
    }
  }, [createdSupplier]);

  useEffect(() => {
    const updateTransactionWithNewEmployee = async () => {
      if (createdByMultiple) {
        updateMultipleTransactions({ params: { EmployeeId: createdEmployee?._id || null }, needTransactionsUpdate: true });
      } else {
        const transaction = transactionsToReconcile[createdEmployeeIndex];
        updateTransaction({ params: { ...transaction, EmployeeId: createdEmployee?._id || null } });
      }
    };
    if (createdEmployee && (createdEmployeeIndex || createdEmployeeIndex === 0 || createdByMultiple)) {
      updateTransactionWithNewEmployee();
      setCreatedByMultiple(false);
    }
  }, [createdEmployee]);

  return (
    <div className="flow-root min-w-full align-middle">
      <table className="min-w-full table-fixed">
        <thead className="sticky top-[68px] z-[2] bg-white h-10">
          <tr>
            <th className="w-9 whitespace-nowrap px-2 text-left text-sm font-semibold bg-white border-b">
              <input
                type="checkbox"
                className="w-4 h-4 ml-1 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                checked={selectedTransactions?.length === transactions.length}
                onChange={() => {
                  if (selectedTransactions?.length === transactions.length) {
                    setSelectedTransactions([]);
                  } else {
                    setSelectedTransactions(transactions);
                  }
                }}
              />
            </th>
            <th scope="col" className="w-24 whitespace-nowrap px-2 py-1 text-left text-xs font-semibold text-gray-500 uppercase bg-white border-b">
              {t("date")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 text-left text-xs font-bold text-gray-500 uppercase bg-white border-b">
              {t("name")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 text-left text-xs font-semibold text-gray-500 uppercase bg-white border-b">
              {t("account")}
            </th>
            <th scope="col" className="w-28 whitespace-nowrap px-2 text-left text-xs font-semibold text-gray-500 uppercase bg-white border-b">
              {t("type")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 text-left text-xs font-semibold text-gray-500 uppercase bg-white border-b">
              {t("tiers")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 text-left text-xs font-semibold text-gray-500 uppercase bg-white border-b">
              {t("category")}
            </th>
            <th scope="col" className="w-20 px-2 text-left text-xs font-semibold text-gray-500 uppercase bg-white border-b">
              {t("proof")}
            </th>
            <th scope="col" className="w-24 whitespace-nowrap px-2 text-left text-xs font-semibold text-gray-500 uppercase bg-white border-b">
              {t("amount")}
            </th>
            <th scope="col" className="w-24 whitespace-nowrap pl-2 pr-4 text-center text-xs font-semibold text-gray-500 uppercase bg-white border-b">
              {t("action")}
            </th>
          </tr>
        </thead>
        <tbody className="relative z-0">
          {loadingTransaction && (
            <tr className="absolute z-10 inset-0 bg-black bg-opacity-30 flex items-start justify-center w-full h-full">
              <td colSpan="10" className="text-center pt-4 sticky top-1/3">
                <Loader className="m-5" />
                <p className="text-sm text-white">{t("transaction.loading_message")}</p>
              </td>
            </tr>
          )}
          {transactionsToReconcile.map((transaction, index) => {
            return (
              <Transaction
                key={transaction._id}
                transaction={transaction}
                index={index}
                setSelectedTransactions={setSelectedTransactions}
                selectedTransactions={selectedTransactions}
                updateTransaction={updateTransaction}
                clientOptions={clientOptions}
                setShowCreateClient={setShowCreateClient}
                setCreatedClientIndex={setCreatedClientIndex}
                supplierOptions={supplierOptions}
                setShowCreateSupplier={setShowCreateSupplier}
                setCreatedSupplierIndex={setCreatedSupplierIndex}
                employeeOptions={employeeOptions}
                setShowCreateEmployee={setShowCreateEmployee}
                setCreatedEmployeeIndex={setCreatedEmployeeIndex}
                setLoading={setLoading}
                loading={loading}
                onSave={onSave}
                accountOptions={accountOptions}
              />
            );
          })}
        </tbody>
      </table>
      <CreateClient
        open={showCreateClient}
        onClose={() => {
          setShowCreateClient(false);
        }}
        onCreated={(data) => {
          setCreatedClient({ ...data });
        }}
      />
      <CreateSupplier
        open={showCreateSupplier}
        onClose={() => {
          setShowCreateSupplier(false);
        }}
        onCreated={(data) => {
          setCreatedSupplier({ ...data });
        }}
      />
      <CreateEmployee
        open={showCreateEmployee}
        onClose={() => {
          setShowCreateEmployee(false);
        }}
        onCreated={(data) => {
          setCreatedEmployee({ ...data });
        }}
      />
    </div>
  );
};

// todo if stripe payout, display a group of transactions, else display normal transactions
// duplicates children dans transactions ?
// mettre les payout avec un code comptable spécifique, pour les exclures stats

const Transaction = ({
  transaction,
  index,
  setSelectedTransactions,
  selectedTransactions,
  updateTransaction,
  setShowCreateClient,
  setCreatedClientIndex,
  clientOptions,
  supplierOptions,
  setShowCreateSupplier,
  setCreatedSupplierIndex,
  employeeOptions,
  setShowCreateEmployee,
  setCreatedEmployeeIndex,
  setLoading,
  loading,
  onSave,
  accountOptions,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { organization } = useSelector((state) => state.Auth);
  const [open, setOpen] = useState(false);
  const [filteredCategories, setFilteredCategories] = useState(categories);

  const isBundledTransactions = transaction?.StripeTransaction?.ReversePayoutTransaction?.length || transaction?.category === "2100";

  useEffect(() => {
    if (organization?.accountingCodes) {
      setFilteredCategories(categories.filter((category) => !organization.accountingCodes.includes(category.id)));
    }
  }, [organization]);

  const handleSaveAndReconcile = async (e, transaction) => {
    try {
      if (!window.confirm("Are you sure you want to reconcile this transaction?")) return;
      e.preventDefault();
      const { ok, code } = await api.put(`/transaction/${transaction._id}`, { ...transaction, status: "RECONCILED" });
      if (transaction.Invoices) {
        transaction.Invoices.forEach(async (invoice) => {
          await api.put(`/invoice/${invoice._id}`, { status: "PAID" });
        });
      }
      if (!ok) throw { code };
      toast.success(t("toast.success.reconciled"));
      onSave();
    } catch (e) {
      toast.error(e.code);
    }
  };

  const canReconcile = (transaction) => {
    if (!transaction.category || !transaction.type) {
      return false;
    }

    if (transaction.type === "CLIENT" && !transaction.ClientId && !transaction.ClientIdSuggestion) {
      return false;
    }

    if (transaction.type === "SUPPLIER" && !transaction.SupplierId && !transaction.SupplierIdSuggestion) {
      return false;
    }

    if (transaction.type === "EMPLOYEE" && !transaction.EmployeeId && !transaction.EmployeeIdSuggestion) {
      return false;
    }

    if (transaction.type === "TRANSFER" && !transaction.TransferAccountId) {
      return false;
    }

    return true;
  };

  const isTransactionSelected = (transaction) => {
    if (selectedTransactions.length === 0) return false;
    return selectedTransactions.some((t) => t._id === transaction._id);
  };

  const getClientLabel = (client) => {
    return client?.entityType === "INDIVIDUAL" ? client?.firstname + " " + client?.lastname : client?.companyName || "";
  };

  const getSupplierLabel = (supplier) => {
    return supplier?.entityType === "INDIVIDUAL" ? supplier?.firstname + " " + supplier?.lastname : supplier?.companyName || "";
  };

  const showAiSuggestions = ["a4f2f18b-baf3-49f8-a3a7-e922e1cd98c0", "8f0ca818-7142-4f47-be67-3ba00f44277e"].includes(organization?._id);

  const renderThirdPartyselect = () => {
    if (transaction.category === "2100") return null;

    if (transaction.type === "SUPPLIER")
      return (
        <>
          <SupplierComboBox
            transaction={transaction}
            onUpdateTransaction={updateTransaction}
            index={index}
            setShowCreateSupplier={setShowCreateSupplier}
            setCreatedSupplierIndex={setCreatedSupplierIndex}
            supplierOptions={supplierOptions}
          />
          {showAiSuggestions && transaction.AiSupplierId && (
            <div className="absolute -bottom-0.5 text-[10px]">
              AI : {getSupplierLabel(supplierOptions.filter((s) => s._id === transaction.AiSupplierId)[0])} - {Math.round(transaction.aiSupplierIdConfidence * 100)}%
            </div>
          )}
        </>
      );
    if (transaction.type === "CLIENT")
      return (
        <>
          <ClientComboBox
            transaction={transaction}
            onUpdateTransaction={updateTransaction}
            index={index}
            setShowCreateClient={setShowCreateClient}
            setCreatedClientIndex={setCreatedClientIndex}
            clientOptions={clientOptions}
          />
          {showAiSuggestions && transaction.AiClientId && (
            <div className="absolute -bottom-0.5 text-[10px]">
              AI : {getClientLabel(clientOptions.filter((c) => c._id === transaction.AiClientId)[0])} - {Math.round(transaction.aiClientIdConfidence * 100)}%
            </div>
          )}
        </>
      );
    if (transaction.type === "EMPLOYEE")
      return (
        <EmployeeComboBox
          transaction={transaction}
          onUpdateTransaction={updateTransaction}
          index={index}
          setShowCreateEmployee={setShowCreateEmployee}
          setCreatedEmployeeIndex={setCreatedEmployeeIndex}
          employeeOptions={employeeOptions}
          width="w-96"
        />
      );
    if (transaction.type === "TRANSFER")
      return (
        <Select
          options={accountOptions}
          value={accountOptions.find((account) => account._id == transaction.TransferAccountId) || null}
          onChange={(e) => {
            updateTransaction({ params: { ...transaction, TransferAccountId: e?._id || null } });
          }}
          placeholder={t("account")}
          getLabel={(account) => <AccountOption account={account} />}
          width="w-full"
          nullable={true}
        />
      );
    return null;
  };

  return (
    <>
      {loading[transaction._id] && (
        <tr className="absolute z-10 flex items-center justify-center w-full h-16">
          <td colSpan="10" className="flex gap-2">
            <Loader size={"small"} />
          </td>
        </tr>
      )}
      <tr
        onClick={() => {
          if (isBundledTransactions) {
            return setOpen((prev) => !prev);
          }
          if (isTransactionSelected(transaction)) {
            setSelectedTransactions(selectedTransactions.filter((t) => t._id !== transaction._id));
          } else {
            setSelectedTransactions([...selectedTransactions, transaction]);
          }
        }}
        key={transaction._id}
        className={classNames(
          "text-xs",
          isTransactionSelected(transaction) ? "bg-blue-200" : index % 2 === 1 ? "bg-white hover:bg-blue-100" : "bg-gray-100 hover:bg-blue-100",
          loading[transaction._id] ? "opacity-40" : "opacity-100",
        )}>
        <td className={`whitespace-nowrap px-2.5`}>
          {isBundledTransactions ? (
            <div
              className="flex items-center gap-1 underline text-gray-600 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                setOpen((prev) => !prev);
              }}>
              <MdArrowRight className={classNames("text-3xl transition duration-100 ease-in-out", open ? "rotate-90" : "rotate-0")} />
            </div>
          ) : (
            <input
              readOnly
              type="checkbox"
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
              checked={isTransactionSelected(transaction)}
            />
          )}
        </td>
        <td className={`w-24 px-2 py-2`}>
          <div className="inline-block">{transaction.date.slice(0, 10)}</div>
        </td>
        <td className={`px-1 w-64 py-2 whitespace-wrap`}>
          <div
            className="cursor-pointer underline font-bold"
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/transactions?id=${transaction._id}`);
            }}>
            {(transaction.name || transaction.description || transaction.additionalInformation)?.length > 50
              ? (transaction.name || transaction.description || transaction.additionalInformation).substring(0, 47) + "..."
              : transaction.name || transaction.description || transaction.additionalInformation}
          </div>
        </td>
        <td className={` w-40  px-2 py-2`}>
          <div className="flex items-center gap-1 w-fit">
            <img src={transaction.bankLogo} height={20} width={20} className="mr-2" />
            <div>{getTransactionAccountName(transaction)}</div>
          </div>
        </td>
        <td className={`max-w-[8rem] relative px-1 py-2`}>
          {transaction.category !== "2100" && (
            <div className="flex flex-col items-center">
              <Select
                options={["CLIENT", "SUPPLIER", "EMPLOYEE", "TRANSFER"]}
                value={transaction.type}
                onChange={async (type) => {
                  const body = { type };
                  if (type === "TRANSFER") {
                    body.category = "8080";
                  }
                  updateTransaction({ params: { ...transaction, ...body } });
                }}
                getLabel={(e) => t(e.toLowerCase())}
                placeholder={t("type")}
                nullable={false}
                width="w-32"
              />
              {showAiSuggestions && transaction.aiType && (
                <div className="absolute -bottom-0.5 text-[10px]">
                  AI : {t(transaction.aiType.toLowerCase())} - {Math.round(transaction.aiTypeConfidence * 100)}%
                </div>
              )}
            </div>
          )}
        </td>

        <td className={`max-w-[8rem] relative px-1 py-2`}>{renderThirdPartyselect()}</td>
        <td className={`max-w-[8rem] relative px-1 py-2`}>
          <div className="flex flex-col items-center">
            <Combobox
              value={categories.find((category) => category.id == transaction.category)}
              options={filteredCategories}
              onChange={async (category) => {
                updateTransaction({ params: { ...transaction, category: category?.id || null }, needTransactionsUpdate: true });
              }}
              getLabel={(e) => (e ? e.id + " - " + t(`transactions.${e.id}`) : null)}
              displayValue={(e) => {
                if (!e) return null;
                return t(`transactions.${e.id}`);
              }}
              placeholder={t("category")}
              width="w-96"
            />
            {showAiSuggestions && transaction.aiCategory && (
              <div className="absolute -bottom-0.5 text-[10px]">
                AI : {transaction.aiCategory} - {Math.round(transaction.aiCategoryConfidence * 100)}%
              </div>
            )}
          </div>
        </td>
        <td className={`px-1 whitespace-nowrap gap-2 m-auto mx-0 w-6`}>
          <div className="flex justify-end items-center gap-1  ">
            {transaction.PurchaseInvoices.length > 0 && (
              <>
                {transaction.PurchaseInvoices.slice(-1).map((invoice, index) => (
                  <div key={index} className="flex justify-center items-center">
                    <a href={invoice.file} target="_blank" rel="noopener noreferrer" title={invoice.fileName}>
                      <FaFileInvoice size={17} />
                      <span className="absolute top-5 scale-0 rounded bg-gray-800 p-2 text-white group-hover:scale-100">{invoice.reference}</span>
                    </a>
                  </div>
                ))}
                {transaction.PurchaseInvoices.length > 1 && (
                  <div className="flex justify-center items-center">
                    <span className="text-gray-500">+ {transaction.PurchaseInvoices.length - 1} </span>
                  </div>
                )}
              </>
            )}

            {transaction.Invoices.length > 0 && (
              <>
                {transaction.Invoices.slice(-1).map((invoice, index) => (
                  <div key={index} className="flex justify-center items-center">
                    <a href={invoice.file} target="_blank" rel="noopener noreferrer" title={invoice.fileName}>
                      <FaFileInvoice size={17} />
                      <span className="absolute top-5 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">{invoice.reference}</span>
                    </a>
                  </div>
                ))}
                {transaction.Invoices.length > 1 && (
                  <div className="flex justify-center items-center">
                    <span className="text-gray-500">+ {transaction.Invoices.length - 1} </span>
                  </div>
                )}
              </>
            )}

            {transaction.amount > 0 ? (
              <FileInputBtn
                onChange={async (file) => {
                  if (!file) return;
                  setLoading((prev) => ({
                    ...prev,
                    [transaction._id]: true,
                  }));
                  const { data } = await api.post("/invoice", {
                    reference: decodeURIComponent(file.target.value.split("/").pop().split(".").shift()),
                    status: "DUE",
                    source: "EXTERNAL",
                    file: file.target.value,
                  });
                  updateTransaction({ params: { ...transaction, Invoices: [data, ...transaction.Invoices] }, needTransactionsUpdate: true });
                  setLoading((prev) => ({
                    ...prev,
                    [transaction._id]: false,
                  }));
                }}
                name={transaction._id}
                folder="Invoices"
              />
            ) : transaction.amount < 0 ? (
              <FileInputBtn
                onChange={async (file) => {
                  if (!file) return;
                  setLoading((prev) => ({
                    ...prev,
                    [transaction._id]: true,
                  }));
                  const { data } = await api.post("/purchase-invoice", {
                    reference: decodeURIComponent(file.target.value.split("/").pop().split(".").shift()),
                    file: file.target.value,
                  });
                  updateTransaction({ params: { ...transaction, PurchaseInvoices: [data, ...transaction.PurchaseInvoices] }, needTransactionsUpdate: true });
                  setLoading((prev) => ({
                    ...prev,
                    [transaction._id]: false,
                  }));
                }}
                name={transaction._id}
                folder="Invoices"
              />
            ) : null}
          </div>
        </td>

        <td className={` w-6 px-1 py-2 whitespace-nowrap`}>
          <div className=" flex items-center gap-1">
            <Amount amount={transaction.amount} currency={currencyToStr(transaction.currency)} />
          </div>
        </td>
        <td className={`pl-2 pr-4 py-2 whitespace-nowrap right-0`}>
          <button
            className="px-2 font-semibold text-xs bg-black text-white h-8 rounded-lg disabled:opacity-50 w-full"
            onClick={(e) => {
              e.stopPropagation();
              handleSaveAndReconcile(e, transaction);
              Mixpanel.track(MIXPANEL_EVENTS.btn_reconcile_transactions);
            }}
            disabled={!canReconcile(transaction)}
            data-tooltip-id={`button-tooltip-${transaction._id}`}
            data-tooltip-content={canReconcile(transaction) ? "" : t("transaction.reconcile_disable")}>
            {t("reconcile")}
          </button>
          <Tooltip id={`button-tooltip-${transaction._id}`} style={{ zIndex: 10, backgroundColor: "#40a5c6", borderRadius: "6px" }} />
        </td>
      </tr>
      {open && !transaction?.StripeTransaction?.ReversePayoutTransaction?.length ? (
        <tr className="hover:bg-gray-100 border-y border-gray-200">
          <td colSpan={10} className="whitespace-nowrap px-2.5 border-l p-2 text-center">
            {t("no_data")}
          </td>
        </tr>
      ) : null}
      {open
        ? transaction?.StripeTransaction?.ReversePayoutTransaction?.map((subTransaction) => {
            return (
              <tr className="text-xs hover:bg-gray-100 border-y border-gray-200">
                <td className={`whitespace-nowrap px-2.5 text-sm border-l`}></td>
                <td className={`w-24 px-2 py-2 text-sm border-l`}>
                  <div className="inline-block">{subTransaction.created.slice(0, 10)}</div>
                </td>
                <td>
                  <div className="flex items-center gap-1 w-fit">
                    <img src={StripeLogo} height={20} width={20} className="mr-2" />
                    <div> {subTransaction.description}</div>
                  </div>
                </td>
                <td />
                <td />
                <td>
                  <div className="flex flex-col">
                    <span>
                      {subTransaction?.Client?.firstname} {subTransaction?.Client?.lastname}
                    </span>
                    <span>{subTransaction?.Client?.companyName ? `${subTransaction?.Client?.companyName}` : ""}</span>
                  </div>
                </td>
                <td>{t(`stripe_transaction.status.${subTransaction?.type?.toUpperCase()}`)}</td>
                <td />
                <td className="w-full">
                  <SubAmount amount={subTransaction.amount} fee={subTransaction.fee} currency={currencyToStr(subTransaction.currency)} />
                </td>
                <td className={`w-24 px-1 py-2 whitespace-nowrap border-r  border-l  right-0`}></td>
              </tr>
            );
          })
        : null}
    </>
  );
};

const Amount = ({ amount, currency }) => {
  let color = amount > 0 ? "text-green-700" : "text-red-700";
  return (
    <div className={`px-2 py-1 rounded-sm flex justify-center items-center font-bold ${color}`}>
      {amount}
      {currency}
    </div>
  );
};

const AccountOption = ({ account }) => {
  const logo = account?.Requisition?.NordigenInstitution?.logo;
  if (!account) return;
  return (
    <span className="flex items-center gap-2">
      {logo ? <img src={logo} height={16} width={16} /> : <FaCashRegister size={16} />}
      <span>{getAccountName(account)}</span>
    </span>
  );
};

const SubAmount = ({ amount, fee, currency }) => {
  let color = amount > 0 ? "text-green-700" : "text-red-700";
  return (
    <div className={`px-2 py-1 rounded-sm flex flex-col justify-center items-end text-sm ${color}`}>
      <span className={color}>
        {amount}
        {currency}
      </span>
      <span className="text-red-700 text-xs">
        -{fee}
        {currency}
      </span>
    </div>
  );
};

export default TransactionsToReconcile;
