import { useEffect, useState } from "react";
import api from "@services/api";
import toast from "react-hot-toast";
import Loader from "@components/utils/Loader";
import Modal from "@components/utils/Modal";
import { Combobox } from "@components/utils/Combobox";
import categories from "@data/categories.js";
import { useTranslation } from "react-i18next";
import { Mixpanel } from "../../services/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";
import { RxCross1 } from "react-icons/rx";

const Edit = ({ supplier, setSupplier, onSave }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState();

  useEffect(() => {
    if (supplier) setValues({ ...supplier });
  }, [supplier]);

  async function handleSubmit(e) {
    try {
      e.preventDefault();
      setLoading(true);
      const { ok, code } = await api.put(`/supplier/${supplier._id}`, { ...values });
      if (!ok) throw { code };
      toast.success(t("toast.sucess.updated_sucessfully"));
      setSupplier(null);
      onSave();
    } catch (e) {
      console.error(t("toast.error"), e);
      toast.error(e.code);
    }
    setLoading(false);
  }

  if (!supplier || !values) return <div />;
  if (loading) return <Loader />;

  return (
    <Modal isOpen={true} onClose={() => setSupplier(null)} innerClassName="flex max-w-[60rem] w-[90vw] max-h-[calc(100vh-5rem)]">
      <div className="w-full max-h-full flex flex-col overflow-y-hidden transform rounded-lg bg-white text-left align-middle shadow-xl transition-all">
        <div className="px-8 pt-8 pb-3 border-b flex justify-between">
          <h2 className="text-2xl font-semibold text-gray-800">{t("supplier.edit")}</h2>
          <button type="button" onClick={() => setSupplier(null)}>
            <RxCross1 />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-6 overflow-auto">
          <div className="mb-6">
            <div className="text-xs text-gray-500 pb-1">{t("type")}</div>
            <div className="flex space-x-4">
              <button
                type="button"
                className={`flex-1 py-2 px-4 rounded-md transition-colors duration-200 ease-in-out ${
                  values.entityType === "INDIVIDUAL" ? "bg-blue-600 text-white" : "bg-gray-100 text-gray-800 hover:bg-gray-200"
                }`}
                onClick={() => setValues({ ...values, entityType: "INDIVIDUAL" })}>
                {t("individual")}
              </button>
              <button
                type="button"
                className={`flex-1 py-2 px-4 rounded-md transition-colors duration-200 ease-in-out ${
                  values.entityType === "COMPANY" ? "bg-blue-600 text-white" : "bg-gray-100 text-gray-800 hover:bg-gray-200"
                }`}
                onClick={() => setValues({ ...values, entityType: "COMPANY" })}>
                {t("company")}
              </button>
            </div>
          </div>
          {values.entityType === "INDIVIDUAL" ? (
            <Individual supplier={supplier} values={values} setValues={setValues} />
          ) : (
            <Company supplier={supplier} values={values} setValues={setValues} />
          )}
          <div className="flex justify-end gap-2 mt-6">
            <button type="submit" className="btn-primary" onClick={() => Mixpanel.track(MIXPANEL_EVENTS.btn_save_supplier)}>
              {t("save")}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

const isShippingAddressDefined = (values) => {
  return values?.shippingAddressStreet || values?.shippingAddressCity || values?.shippingAddressZipCode || values?.shippingAddressCountry ? true : false;
};
const ShippingAddress = ({ supplier, values, setValues }) => {
  const { t } = useTranslation();
  const [hasShippingAddress, setHasShippingAddress] = useState();

  useEffect(() => {
    setHasShippingAddress(isShippingAddressDefined(supplier));
  }, [supplier]);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-2">
        <input
          type="checkbox"
          className="cursor-pointer"
          checked={hasShippingAddress ?? false}
          onChange={(e) => {
            setHasShippingAddress(e.target.checked);
            if (!e.target.checked) {
              setValues({ ...values, shippingAddressStreet: null, shippingAddressCity: null, shippingAddressZipCode: null, shippingAddressCountry: null });
            }
          }}
          id="hasShippingAddress"
        />
        <label htmlFor="hasShippingAddress" className="text-gray-800 select-none">
          {t("shipping_address_differs")}
        </label>
      </div>

      {hasShippingAddress && (
        <>
          <h3 className="text-base font-semibold">{t("shipping_address")}</h3>
          <div className="grid grid-cols-2 gap-4">
            <div className="flex flex-col">
              <div className="text-xs text-gray-500 pb-1"> {t("street")} </div>
              <input
                type="text"
                value={values?.shippingAddressStreet ?? ""}
                onChange={(e) => setValues({ ...values, shippingAddressStreet: e.target.value })}
                className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
                placeholder={t("supplier.edit.street_placeholder")}
              />
            </div>
            <div className="flex flex-col">
              <div className="text-xs text-gray-500 pb-1"> {t("city")} </div>
              <input
                type="text"
                value={values?.shippingAddressCity ?? ""}
                onChange={(e) => setValues({ ...values, shippingAddressCity: e.target.value })}
                className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
                placeholder={t("supplier.edit.city_placeholder")}
              />
            </div>
            <div className="flex flex-col row-start-2">
              <div className="text-xs text-gray-500 pb-1"> {t("zip_code")} </div>
              <input
                type="text"
                value={values?.shippingAddressZipCode ?? ""}
                onChange={(e) => setValues({ ...values, shippingAddressZipCode: e.target.value })}
                className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
                placeholder={t("supplier.edit.zip_placeholder")}
              />
            </div>
            <div className="flex flex-col row-start-2">
              <div className="text-xs text-gray-500 pb-1"> {t("country")} </div>
              <input
                type="text"
                value={values?.shippingAddressCountry ?? ""}
                onChange={(e) => setValues({ ...values, shippingAddressCountry: e.target.value })}
                className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
                placeholder={t("supplier.edit.country_placeholder")}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const Individual = ({ supplier, values, setValues }) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-4">
      <div className="grid grid-cols-2 gap-6">
        <div className="flex flex-col">
          <div className="text-xs text-gray-500 pb-1">{t("first_name")}</div>
          <input
            required
            type="text"
            value={values.firstname || ""}
            onChange={(e) => setValues({ ...values, firstname: e.target.value })}
            className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
            placeholder={t("supplier.edit.first_name_placeholder")}
          />
        </div>
        <div className="flex flex-col">
          <div className="text-xs text-gray-500 pb-1">{t("last_name")}</div>
          <input
            required
            type="text"
            value={values.lastname || ""}
            onChange={(e) => setValues({ ...values, lastname: e.target.value })}
            className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
            placeholder={t("supplier.edit.last_name_placeholder")}
          />
        </div>
        <div className="flex flex-col row-start-2">
          <label htmlFor="categories" className="text-xs text-gray-500 pb-1">
            {t("default_category")}
          </label>
          <Combobox
            value={categories.find((category) => category.id == values.defaultCategory)}
            options={categories}
            onChange={(value) => setValues({ ...values, defaultCategory: value?.id || null })}
            getLabel={(e) => (e ? e.id + " - " + t(`transactions.${e.id}`) : null)}
            placeholder={t("select_category")}
          />
        </div>
        <div className="flex flex-col row-start-2">
          <div className="text-xs text-gray-500 pb-1">{t("website")}</div>
          <input
            type="text"
            value={values.website ?? ""}
            onChange={(e) => setValues({ ...values, website: e.target.value })}
            className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
            placeholder={t("supplier.edit.website_placeholder")}
          />
        </div>
        <div className="flex flex-col row-start-3">
          <div className="text-xs text-gray-500 pb-1">{t("phone_number")}</div>
          <input
            type="text"
            value={values.phone ?? ""}
            onChange={(e) => setValues({ ...values, phone: e.target.value })}
            className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
            placeholder={t("supplier.edit.phone_number_placeholder")}
          />
        </div>
        <div className="flex flex-col row-start-3">
          <div className="text-xs text-gray-500 pb-1">{t("email")}</div>
          <input
            type="text"
            defaultValue={values.email ?? ""}
            onChange={(e) => setValues({ ...values, email: e.target.value })}
            className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
            placeholder={t("supplier.edit.email_placeholder")}
          />
        </div>
      </div>
      <div className="flex flex-col">
        <div className="text-xs text-gray-500 pb-1">{t("memo_note")}</div>
        <textarea
          type="text"
          value={values.memo ?? ""}
          onChange={(e) => setValues({ ...values, memo: e.target.value })}
          className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
          placeholder={t("memo_note.to_enter.placeholder")}
        />
      </div>

      <div className="border-t border-t-gray-300 mt-2 mb-4"></div>

      <h2 className="text-base font-semibold">{t("billing_address")}</h2>
      <div className="grid grid-cols-2 gap-4">
        <div className="flex flex-col">
          <div className="text-xs text-gray-500 pb-1">{t("street")}</div>
          <input
            type="text"
            value={values.billingAddressStreet ?? ""}
            onChange={(e) => setValues({ ...values, billingAddressStreet: e.target.value })}
            className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
            placeholder={t("supplier.edit.street_placeholder")}
          />
        </div>
        <div className="flex flex-col">
          <div className="text-xs text-gray-500 pb-1">{t("city")}</div>
          <input
            type="text"
            value={values.billingAddressCity ?? ""}
            onChange={(e) => setValues({ ...values, billingAddressCity: e.target.value })}
            className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
            placeholder={t("supplier.edit.city_placeholder")}
          />
        </div>
        <div className="flex flex-col row-start-2">
          <div className="text-xs text-gray-500 pb-1">{t("zip_code")}</div>
          <input
            type="text"
            value={values.billingAddressZipCode ?? ""}
            onChange={(e) => setValues({ ...values, billingAddressZipCode: e.target.value })}
            className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
            placeholder={t("supplier.edit.zip_placeholder")}
          />
        </div>
        <div className="flex flex-col row-start-2">
          <div className="text-xs text-gray-500 pb-1">{t("country")}</div>
          <input
            type="text"
            value={values.billingAddressCountry ?? ""}
            onChange={(e) => setValues({ ...values, billingAddressCountry: e.target.value })}
            className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
            placeholder={t("supplier.edit.country_placeholder")}
          />
        </div>
      </div>

      <ShippingAddress supplier={supplier} values={values} setValues={setValues} />
    </div>
  );
};

const Company = ({ supplier, values, setValues }) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-4">
      <div className="grid grid-cols-2 gap-6">
        <div className="flex flex-col">
          <div className="text-xs text-gray-500 pb-1">{t("company_name")}</div>
          <input
            required
            type="text"
            value={values.companyName || ""}
            onChange={(e) => setValues({ ...values, companyName: e.target.value })}
            className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
            placeholder={t("supplier.edit.name_placeholder")}
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="categories" className="text-xs text-gray-500 pb-1">
            {t("default_category")}
          </label>
          <Combobox
            value={categories.find((category) => category.id == values.defaultCategory)}
            options={categories}
            onChange={(value) => setValues({ ...values, defaultCategory: value?.id || null })}
            getLabel={(e) => (e ? e.id + " - " + t(`transactions.${e.id}`) : null)}
            placeholder={t("select_category")}
          />
        </div>
      </div>
      <div className="flex flex-col">
        <div className="text-xs text-gray-500 pb-1">{t("website")}</div>
        <input
          type="text"
          value={values.website ?? ""}
          onChange={(e) => setValues({ ...values, website: e.target.value })}
          className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
          placeholder={t("supplier.edit.website_placeholder")}
        />
      </div>
      <div className="flex flex-col">
        <div className="text-xs text-gray-500 pb-1">{t("business_registration_number")}</div>
        <input
          type="text"
          defaultValue={values.registrationNumber ?? ""}
          onChange={(e) => setValues({ ...values, registrationNumber: e.target.value })}
          className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
          placeholder={t("supplier.edit.business_registration_number_placeholder")}
        />
      </div>
      <div className="flex flex-col">
        <div className="text-xs text-gray-500 pb-1">{t("memo_note")}</div>
        <textarea
          type="text"
          value={values.memo ?? ""}
          onChange={(e) => setValues({ ...values, memo: e.target.value })}
          className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
          placeholder={t("memo_note.to_enter.placeholder")}
        />
      </div>

      <div className="border-t border-t-gray-300 mt-2 mb-4"></div>

      <h2 className="text-base font-semibold"> {t("billing_address")} </h2>
      <div className="grid grid-cols-2 grid-rows-2 gap-4">
        <div className="flex flex-col">
          <div className="text-xs text-gray-500 pb-1">{t("street")}</div>
          <input
            type="text"
            value={values.billingAddressStreet ?? ""}
            onChange={(e) => setValues({ ...values, billingAddressStreet: e.target.value })}
            className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
            placeholder={t("supplier.edit.street_placeholder")}
          />
        </div>
        <div className="flex flex-col">
          <div className="text-xs text-gray-500 pb-1">{t("city")}</div>
          <input
            type="text"
            value={values.billingAddressCity ?? ""}
            onChange={(e) => setValues({ ...values, billingAddressCity: e.target.value })}
            className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
            placeholder={t("supplier.edit.city_placeholder")}
          />
        </div>
        <div className="flex flex-col row-start-2">
          <div className="text-xs text-gray-500 pb-1">{t("zip_code")}</div>
          <input
            type="text"
            value={values.billingAddressZipCode ?? ""}
            onChange={(e) => setValues({ ...values, billingAddressZipCode: e.target.value })}
            className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
            placeholder={t("supplier.edit.zip_placeholder")}
          />
        </div>
        <div className="flex flex-col row-start-2">
          <div className="text-xs text-gray-500 pb-1">{t("country")}</div>
          <input
            type="text"
            value={values.billingAddressCountry ?? ""}
            onChange={(e) => setValues({ ...values, billingAddressCountry: e.target.value })}
            className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
            placeholder={t("supplier.edit.country_placeholder")}
          />
        </div>
      </div>

      <ShippingAddress supplier={supplier} values={values} setValues={setValues} />
    </div>
  );
};
export default Edit;
