import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import Modal from "@components/utils/Modal";
import { currencies } from "@constants";
import api from "@services/api";
import FileInput from "@components/utils/FileInput";
import FilesInput from "@components/utils/FilesInput";
import { Mixpanel } from "@services/mixpanel";
import { MIXPANEL_EVENTS } from "@utils/mixpanelEvents";
import Loader from "@components/utils/Loader";
import { Combobox } from "@components/utils/Combobox";
import { Select } from "@components/utils/Select";
import { RxCross1 } from "react-icons/rx";
import { AiFillFileImage } from "react-icons/ai";
import { MdDeleteOutline } from "react-icons/md";
import { FaRegFile } from "react-icons/fa";

const EditPurchaseInvoiceModal = ({ isOpen, onClose, onSave, showCreateSupplierModal, createdSupplier, taxOptions }) => {
  const [purchaseInvoice, setPurchaseInvoice] = useState({});
  const [suppliers, setSuppliers] = useState([]);
  const { search } = useLocation();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const fetchPurchaseInvoice = async (purchaseInvoiceId) => {
    const res = await api.get(`/purchase-invoice/${purchaseInvoiceId}`);
    if (!res.ok) toast.error(t("toast.error.fetching_trans"));
    setPurchaseInvoice(res.data);
  };

  useEffect(() => {
    const query = new URLSearchParams(search);
    const purchaseInvoiceId = query.get("id");
    if (!purchaseInvoiceId) return onClose();
    fetchPurchaseInvoice(purchaseInvoiceId);
  }, [isOpen]);

  const getSuppliers = async () => {
    const { data } = await api.post("/supplier/search", {
      per_page: 1000,
    });

    setSuppliers(data.suppliers);
  };
  useEffect(() => {
    getSuppliers();
  }, []);

  useEffect(() => {
    if (createdSupplier) {
      getSuppliers();
      setPurchaseInvoice({ ...purchaseInvoice, SupplierId: createdSupplier._id || null });
    }
  }, [createdSupplier]);

  const handleClose = () => {
    setPurchaseInvoice(null);
    onClose();
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const { ok, code } = await api.put(`/purchase-invoice/${purchaseInvoice._id}`, { ...purchaseInvoice, amount: purchaseInvoice.amount.replace(",", ".") });
      if (!ok) throw { code };
      onSave();
      toast.success(t("toast.sucess.updated_sucessfully"));
      handleClose();
    } catch (e) {
      console.error(t("toast.error"), e);
      toast.error(e.code);
    }
    setLoading(false);
  };

  const navigateToOrgSettings = () => {
    if (!window.confirm(t("confirm_unsaved_message"))) return;
    navigate("/settings/my-organizations");
  };

  if (loading) return <Loader />;
  if (!purchaseInvoice) return null;

  return (
    <Modal isOpen={isOpen} onClose={handleClose} innerClassName="flex max-w-[60rem] w-[90vw] max-h-[calc(100vh-5rem)]">
      <div className="w-full max-h-full flex flex-col overflow-y-hidden transform rounded-lg bg-white text-left align-middle shadow-xl transition-all">
        <div className="px-8 pt-8 pb-3 border-b flex justify-between">
          <h2 className="text-2xl font-semibold text-gray-800">{t("purchase_invoices.edit")}</h2>
          <button type="button" onClick={handleClose}>
            <RxCross1 />
          </button>
        </div>
        <form onSubmit={handleSubmit} className="p-6 overflow-auto">
          <div className="flex flex-col gap-4">
            <div className="grid grid-cols-2 gap-6">
              <div className="flex flex-col">
                <div className="text-xs text-gray-500 pb-1">{t("reference")}</div>
                <input
                  type="text"
                  value={purchaseInvoice.reference}
                  onChange={(e) => setPurchaseInvoice({ ...purchaseInvoice, reference: e.target.value })}
                  className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
                  placeholder={t("reference")}
                />
              </div>
              <div className="flex flex-col">
                <div className="text-xs text-gray-500 pb-1">{t("supplier")}</div>
                <Combobox
                  options={suppliers}
                  value={suppliers.find((supplier) => supplier._id === purchaseInvoice?.SupplierId)}
                  placeholder={t("choose_supplier")}
                  onChange={(selectedClient) => {
                    setPurchaseInvoice({ ...purchaseInvoice, SupplierId: selectedClient?._id || null });
                  }}
                  getLabel={(supplier) => {
                    if (supplier.entityType === "COMPANY") return supplier.companyName || "";
                    else if (supplier.entityType === "INDIVIDUAL") return supplier.firstname + " " + supplier.lastname || "";
                    return "";
                  }}
                  listHeader={
                    <button className="px-3 py-2 bg-black-100 w-full text-white flex items-center justify-between font-semibold text-sm" onClick={() => showCreateSupplierModal()}>
                      {t("supplier.create_supplier")} <span>+</span>
                    </button>
                  }
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-6">
              <div className="flex flex-col">
                <div className="text-xs text-gray-500 pb-1">{t("currency")}</div>
                <Select
                  options={currencies}
                  value={currencies.find((currency) => currency.id == purchaseInvoice.currency)}
                  onChange={(e) => setPurchaseInvoice({ ...purchaseInvoice, currency: e.id })}
                  getLabel={(e) => (
                    <span>
                      <span className="bg-neutral-100 px-2 mr-2">{e.symbol}</span>
                      {e.name}
                    </span>
                  )}
                  placeholder={t("select_currency")}
                  className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
                  nullable={false}
                />
              </div>
              <div className="flex flex-col">
                <div className="text-xs text-gray-500 pb-1">
                  {t("amount")} ({purchaseInvoice.currency})
                </div>
                <input
                  type="text"
                  value={purchaseInvoice.amount}
                  onChange={(e) => setPurchaseInvoice({ ...purchaseInvoice, amount: e.target.value })}
                  className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
                  placeholder={t("amount")}
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-6">
              <div className="flex flex-col">
                <div className="text-xs text-gray-500 pb-1">
                  {t("vat")} ({purchaseInvoice.currency})
                </div>
                <Combobox
                  options={taxOptions}
                  value={taxOptions.find((value) => value._id === purchaseInvoice.TaxId)}
                  onChange={(value) => setPurchaseInvoice({ ...purchaseInvoice, TaxId: value._id || null })}
                  width="w-44"
                  listHeader={
                    <button
                      type="button"
                      className="px-3 py-2 bg-black-100 text-white w-full flex items-center justify-between font-semibold text-xs"
                      onClick={navigateToOrgSettings}>
                      {t("add_vat")} <span>+</span>
                    </button>
                  }
                  placeholder="Type"
                  getLabel={(tax) => tax.name}
                />
              </div>

              <div className="flex flex-col">
                <div className="text-xs text-gray-500 pb-1">{t("purchase_invoices.issuedate")}</div>
                <input
                  type="date"
                  value={purchaseInvoice.issueDate ? purchaseInvoice.issueDate.slice(0, 10) : ""}
                  onChange={(e) => setPurchaseInvoice({ ...purchaseInvoice, issueDate: e.target.value })}
                  className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
                />
              </div>

              <div className="flex flex-col">
                <div className="text-xs text-gray-500 pb-1">{t("purchase_invoices.duedate")}</div>
                <input
                  type="date"
                  value={purchaseInvoice.dueDate ? purchaseInvoice.dueDate.slice(0, 10) : ""}
                  onChange={(e) => setPurchaseInvoice({ ...purchaseInvoice, dueDate: e.target.value })}
                  className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
                />
              </div>
            </div>

            <div className="flex flex-col">
              <div className="text-xs text-gray-500 pb-1">{t("purchase_invoices.invoice_file")}</div>
              {purchaseInvoice?.file ? (
                <div className="border rounded-lg p-4 relative w-full">
                  <div className="flex justify-between items-start mb-2">
                    <a href={purchaseInvoice.file} target="_blank" rel="noopener noreferrer" className="text-gray-500">
                      {purchaseInvoice.file.indexOf(".pdf") !== -1 ? <FaRegFile size={18} /> : <AiFillFileImage size={18} />}
                    </a>
                    <button type="button" onClick={() => setPurchaseInvoice({ ...purchaseInvoice, file: null })} className="text-gray-500 hover:text-red-500">
                      <MdDeleteOutline size={20} />
                    </button>
                  </div>
                  <a href={purchaseInvoice.file} target="_blank" rel="noopener noreferrer" className="font-bold hover:underline">
                    {decodeURIComponent(purchaseInvoice.file.split("/").pop())}
                  </a>
                </div>
              ) : (
                <div className="flex gap-3">
                  <FileInput
                    onChange={(e) => setPurchaseInvoice({ ...purchaseInvoice, file: e.target.value })}
                    name={`${purchaseInvoice._id}-main`}
                    folder="purchaseInvoiceTransaction"
                    width="w-full"
                  />
                </div>
              )}
            </div>

            <div className="border-t border-t-gray-300 mt-2 pt-4">
              <h2 className="text-base font-semibold mb-4">{t("purchase_invoices.additional_files")}</h2>
              <div className="flex flex-col gap-4">
                <div className="flex gap-3">
                  <FileInput
                    onChange={(e) => setPurchaseInvoice({ ...purchaseInvoice, files: [e.target.value, ...purchaseInvoice.files] })}
                    name={`${purchaseInvoice._id}-additional`}
                    folder="purchaseInvoiceTransaction"
                    width="w-full"
                  />
                </div>
                <div className="grid grid-cols-2 gap-4">
                  {(purchaseInvoice.files || []).map((file, index) => (
                    <div key={index} className="border rounded-lg p-4 relative">
                      <div className="flex justify-between items-start mb-2">
                        <a href={file} target="_blank" rel="noopener noreferrer" className="text-gray-500">
                          {file.indexOf(".pdf") !== -1 ? <FaRegFile size={18} /> : <AiFillFileImage size={18} />}
                        </a>
                        <button
                          type="button"
                          onClick={() => {
                            const newFiles = [...purchaseInvoice.files];
                            newFiles.splice(index, 1);
                            setPurchaseInvoice({ ...purchaseInvoice, files: newFiles });
                          }}
                          className="text-gray-500 hover:text-red-500">
                          <MdDeleteOutline size={20} />
                        </button>
                      </div>
                      <a href={file} target="_blank" rel="noopener noreferrer" className="font-bold hover:underline">
                        {decodeURIComponent(file.split("/").pop())}
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end gap-2 mt-6">
            <button type="button" className="btn-secondary" onClick={handleClose}>
              {t("cancel")}
            </button>
            <button
              type="submit"
              className="btn-primary"
              onClick={(e) => {
                Mixpanel.track(MIXPANEL_EVENTS.btn_save_purchase_invoice);
              }}>
              {t("save")}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default EditPurchaseInvoiceModal;
