import { useState, useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import api from "@services/api";
import Loader from "@components/utils/Loader";

import { Paginator } from "@components/utils/Pagination";
import { SearchBar } from "@components/utils/SearchBar";
import ActionsMenu from "@components/utils/ActionsMenu";
import CreatePurchaseInvoice from "./createPurchaseInvoices";

import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { Mixpanel } from "../../services/mixpanel";
import CreateSupplier from "@components/modals/createSupplier.jsx";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";
import { useSelector } from "react-redux";
import EditPurchaseInvoiceModal from "./editPurchaseInvoices";

const List = () => {
  const query = new URLSearchParams(useLocation().search);
  const { t } = useTranslation();
  const [open, setOpen] = useState(query.get("id"));
  const [filter, setFilter] = useState({ per_page: 8, page: 1, search: "" });
  const [purchaseInvoice, setPurchaseInvoice] = useState([]);
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();
  const [showCreateSupplier, setShowCreateSupplier] = useState(false);
  const [createdSupplier, setCreatedSupplier] = useState(null);
  const { organization } = useSelector((state) => state.Auth);

  const getPurchaseInvoices = async () => {
    const { data } = await api.post("/purchase-invoice/search", {
      per_page: filter.per_page,
      page: filter.page,
      search: filter.search,
      OrganizationId: organization._id,
    });

    setPurchaseInvoice(data.purchaseInvoices);
    setTotal(data.total);
  };

  const deletePurchaseInvoices = async (invoiceID) => {
    const confirm = window.confirm("Are you sure ?");
    if (!confirm) return;
    await api.remove(`/purchase-invoice/${invoiceID}`);
    toast.success(t("toast.sucess.removed"));
    getPurchaseInvoices();
  };

  useEffect(() => {
    if (!organization) return;
    getPurchaseInvoices();
  }, [filter, organization]);

  useEffect(() => {
    if (query.get("id")) setOpen(true);
  }, [query]);

  useEffect(() => {
    Mixpanel.track(MIXPANEL_EVENTS["page_view_purchase-invoices"]);
  }, []);

  if (!purchaseInvoice) return <Loader />;

  return (
    <div className="mx-auto">
      <div className="py-6 px-6">
        <div className="flex flex-col bg-white rounded-xl p-6">
          <div className="flex justify-between items-start">
            <div>
              <div className="text-2xl font-bold "> {t("purchase_invoices")} </div>
              <p className="mb-5 mt-2 text-base text-gray-600">{t("purchase_invoices.dsc")}</p>
            </div>
            <CreatePurchaseInvoice
              onCreated={(el) => {
                getPurchaseInvoices();
                navigate(`/purchase-invoice?id=${el._id}`);
              }}
            />
          </div>

          <SearchBar search={filter.search} setFilter={setFilter} />
          <EditPurchaseInvoiceModal
            isOpen={open}
            onClose={() => {
              navigate("/purchase-invoice");
              setOpen(false);
            }}
            onSave={getPurchaseInvoices}
            showCreateSupplierModal={() => {
              setOpen(false);
              setShowCreateSupplier(true);
              setCreatedSupplier(null);
            }}
            createdSupplier={createdSupplier}
            taxOptions={organization.Taxes}
          />
          <CreateSupplier
            open={showCreateSupplier}
            onClose={() => {
              setShowCreateSupplier(false);
            }}
            onCreated={(data) => {
              setCreatedSupplier({ ...data });
            }}
          />
          <TablePurchaseInvoice purchaseInvoice={purchaseInvoice} onRemove={deletePurchaseInvoices} />
          <div className="flex flex-row items-center justify-center mt-4">
            <Paginator page={filter.page} setPage={(p) => setFilter({ ...filter, page: p })} last={Math.ceil(total / filter.per_page)} />
          </div>
        </div>
      </div>
    </div>
  );
};

const TablePurchaseInvoice = ({ purchaseInvoice, onRemove }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const getSupplierName = (supplier) => {
    if (!supplier) return "";
    if (supplier.entityType === "COMPANY") return supplier.companyName || "";
    else if (supplier.entityType === "INDIVIDUAL") return supplier.firstname + " " + supplier.lastname || "";
    return "";
  };

  return (
    <div className="overflow-x-auto mt-4 flow-root min-w-full align-middle rounded-lg border">
      <table className="min-w-full ">
        <thead className="border-b">
          <tr>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-bold text-gray-900 uppercase">
              {t("reference")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-500 uppercase">
              {t("vat")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-500 uppercase">
              {t("amount")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-500 uppercase">
              {t("currency")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-500 uppercase">
              {t("purchase_invoices.issuedate")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-500 uppercase">
              {t("purchase_invoices.duedate")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-500 uppercase">
              {t("supplier")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-500 uppercase">
              {t("actions")}
            </th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {purchaseInvoice?.map((invoice) => (
            <tr key={invoice._id} className="hover:bg-[#dee2e6]">
              <td className="whitespace-nowrap px-2 py-2 text-sm">
                <div
                  className="cursor-pointer underline font-bold"
                  onClick={() => {
                    navigate(`/purchase-invoice?id=${invoice._id}`);
                    Mixpanel.track(MIXPANEL_EVENTS.page_view_purchase_invoice_edit);
                  }}>
                  {invoice.reference}
                </div>
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm">
                <div>{invoice.Tax?.name}</div>
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm">
                <div>{invoice.amount}</div>
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm">
                <div>{invoice.currency}</div>
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm">
                <div>{invoice?.issueDate ? new Date(invoice.issueDate).toLocaleDateString().split("T")[0] : "-"}</div>
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm">
                <div>{invoice?.dueDate ? new Date(invoice.dueDate).toLocaleDateString().split("T")[0] : "-"}</div>
              </td>

              <td className="whitespace-nowrap px-2 py-2 text-sm">{getSupplierName(invoice.Supplier)}</td>
              <td className="whitespace-nowrap px-2 py-2 text-sm z-20 w-4">
                <ActionsMenu actions={[{ label: t("delete"), callback: () => onRemove(invoice._id) }]} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default List;
