import React, { useState } from "react";
import { classNames } from "../../utils";
import { useTranslation } from "react-i18next";
import List from "./list";
import ProfitLoss from "./profitLoss";
import Tax from "@scenes/analytics/tax";
import Ledger from "./ledger";
import PaiementLinks from "./paymentLinks";
import { ENVIRONMENT } from "../../config";

const Index = () => {
  const { t } = useTranslation();

  const TABS = {
    ACCOUNTING: t("analytics.tab_accounting"),
    PROFIT_LOSS: t("analytics.tab_profit_loss"),
    POTENTIAL: t("analytics.tab_potential"),
    TAX: t("analytics.tab_tax_report"),
    LEDGER: t("analytics.tab_ledger"),
    PAYMENT_LINKS: t("analytics.tab_payment_links"),
  };

  const [tab, setTab] = useState(TABS.PROFIT_LOSS);

  const render = () => {
    if (tab === TABS.ACCOUNTING) return <List />;
    if (tab === TABS.PROFIT_LOSS) return <ProfitLoss />;
    if (tab === TABS.TAX) return <Tax />;
    if (tab === TABS.LEDGER) return <Ledger />;
    if (tab === TABS.PAYMENT_LINKS) return <PaiementLinks />;

    if (tab === TABS.POTENTIAL) return <div className="text-center mt-10"> {t("coming_soon")} </div>;
    return <div />;
  };

  return (
    <div className="mx-auto">
      <div className="py-2 px-6">
        <div className="">
          <nav className="flex flex-row w-full justify-start items-center mb-2">
            <div className="flex w-full p-2 gap-4 bg-white rounded-xl border border-gray-300">
              <TabItem title={TABS.PROFIT_LOSS} tab={TABS.PROFIT_LOSS} setTab={setTab} active={tab === TABS.PROFIT_LOSS} />
              <TabItem title={TABS.ACCOUNTING} tab={TABS.ACCOUNTING} setTab={setTab} active={tab === TABS.ACCOUNTING} />
              <TabItem title={TABS.TAX} tab={TABS.TAX} setTab={setTab} active={tab === TABS.TAX} />
              <TabItem title={TABS.LEDGER} tab={TABS.LEDGER} setTab={setTab} active={tab === TABS.LEDGER} />
              <TabItem title={TABS.PAYMENT_LINKS} tab={TABS.PAYMENT_LINKS} setTab={setTab} active={tab === TABS.PAYMENT_LINKS} />
            </div>
          </nav>
          <div className="w-full py-2">{render()}</div>
        </div>
      </div>
    </div>
  );
};

const TabItem = ({ tab, title, setTab, active, RightComponent }) => {
  return (
    <button
      onClick={() => setTab(tab)}
      className={classNames(
        "flex-1 p-1 text-center font-medium text-sm transition-all duration-200 bg-white rounded-xl",
        active ? "text-blue-500 border-2 border-blue-500" : "text-gray-500 border-2 hover:border-blue-200 border-gray-100",
      )}>
      <div className="flex items-center justify-center gap-2">
        {title}
        {RightComponent}
      </div>
    </button>
  );
};

export default Index;
