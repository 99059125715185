import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import api from "@services/api";
import categories from "@data/categories.js";
import { Mixpanel } from "@services/mixpanel";
import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";
import { AiFillFileImage } from "react-icons/ai";
import { MdDeleteOutline } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";

import Loader from "@components/utils/Loader";
import { Combobox } from "@components/utils/Combobox";
import FileInput from "@components/utils/FileInput";
import Modal from "@components/utils/Modal";
import { Select } from "@components/utils/Select";
import { Tooltip } from "react-tooltip";
import { currencyToStr, getAccountName } from "@utils";
import { useSelector } from "react-redux";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";
import { useNavigate } from "react-router-dom";
import { FaRegFile } from "react-icons/fa";
import { FaCashRegister } from "react-icons/fa6";

const CreateTransaction = ({
  isOpen,
  onClose,
  onSave,
  setShowCreateClientModal,
  showCreateSupplierModal,
  createdClient,
  createdSupplier,
  pettyCashAccount,
  accountOptions,
  taxOptions,
}) => {
  const [clientOptions, setClientOptions] = useState([]);
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [invoiceOptions, setInvoiceOptions] = useState([]);
  const [transaction, setTransaction] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [selectedAccount, setSelectedAccount] = useState(null);
  const user = useSelector((state) => state.Auth.user);
  const organization = useSelector((state) => state.Auth.organization);
  const navigate = useNavigate();
  const [filteredCategories, setFilteredCategories] = useState(categories);

  const navigateToOrgSettings = () => {
    if (!window.confirm(t("confirm_unsaved_message"))) return;
    navigate("/settings/my-organizations");
  };

  const getClients = async () => {
    const { data } = await api.post(`/client/search`, { per_page: 1000 });
    setClientOptions(data.clients);
  };

  const getSuppliers = async () => {
    const { data } = await api.post(`/supplier/search`, { per_page: 1000 });
    setSupplierOptions(data.suppliers);
  };

  useEffect(() => {
    getClients();
    getSuppliers();
    const newTransaction = {
      date: new Date().toISOString().slice(0, -8),
      status: "TO_RECONCILE",
      amount: 0,
      type: "SUPPLIER",
      organizationName: user.UserOrganizations?.[0].organizationName,
    };
    setTransaction(newTransaction);
    setSelectedAccount(pettyCashAccount ? { ...pettyCashAccount } : { ...accountOptions[0] });
  }, [isOpen, pettyCashAccount]);

  useEffect(() => {
    if (createdClient) {
      setTransaction((transaction) => ({ ...transaction, ClientId: createdClient?._id || null }));
    }
  }, [createdClient]);

  useEffect(() => {
    const getInvoices = async () => {
      const { data } = await api.post("/invoice/search", { per_page: 1000, statuses: ["SENT", "PAID", "DUE"] });
      setInvoiceOptions(data.invoices);
    };
    getInvoices();
  }, [transaction]);

  useEffect(() => {
    if (createdSupplier) {
      setTransaction((transaction) => ({ ...transaction, SupplierId: createdSupplier?._id || null }));
    }
  }, [createdSupplier]);

  useEffect(() => {
    setTransaction((t) => ({
      ...t,
      AccountId: selectedAccount?._id,
      bankLogo: selectedAccount?.Requisition?.NordigenInstitution.logo ?? null,
      bankName: selectedAccount?.Requisition?.NordigenInstitution.name ?? null,
      currency: selectedAccount?.currency,
      accountOwner: selectedAccount?.ownerName,
      accountDetails: selectedAccount?.details,
    }));
  }, [selectedAccount]);

  useEffect(() => {
    if (organization?.accountingCodes) {
      setFilteredCategories(categories.filter((category) => !organization.accountingCodes.includes(category.id)));
    }
  }, [organization]);

  const handleSave = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const { ok, code } = await api.post(`/transaction`, { ...transaction });
      if (!ok) throw { code };
      onSave();
      toast.success(t("toast.sucess.created"));
      handleClose();
    } catch (e) {
      console.error(t("toast.error"), e);
      toast.error(e.code);
    }
    setLoading(false);
  };

  const handleSaveAndReconcile = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const { ok, code } = await api.post(`/transaction`, { ...transaction, status: "RECONCILED" });
      if (!ok) throw { code };
      onSave();
      toast.success(t("toast.success.created_reconciled"));
      handleClose();
    } catch (e) {
      console.error(t("toast.error"), e);
      toast.error(e.code);
    }
    setLoading(false);
  };

  const handleClose = () => {
    setTransaction(null);
    onClose();
  };

  const clientOrSupplierOption = (item) => {
    if (!item) return null;
    return item.entityType === "INDIVIDUAL" ? item.firstname + " " + item.lastname : item.companyName;
  };

  const canCreate = () => {
    return transaction.AccountId && transaction.date && transaction.amount && transaction.name;
  };
  const canReconcile = () => {
    return (
      transaction.AccountId &&
      transaction.date &&
      transaction.amount &&
      transaction.name &&
      transaction.category &&
      (transaction.ClientId || transaction.SupplierId || transaction.EmployeeId)
    );
  };

  if (loading) return <Loader />;
  if (!transaction) return null;

  return (
    <Modal isOpen={isOpen} onClose={handleClose} innerClassName="flex max-w-[50rem] w-[60vw] max-h-[calc(100vh-5rem)]">
      <div className="w-full max-h-full flex flex-col overflow-y-hidden transform rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
        <div className="px-8 pt-8 pb-3 border-b flex justify-between">
          <div className="text-left text-xl">{t("transactions.create_transaction")}</div>
          <button type="submit" onClick={handleClose}>
            <RxCross1 />
          </button>
        </div>
        <div className="py-4 px-8 overflow-auto">
          <div className="flex flex-col gap-4">
            <div className="grid grid-cols-2 gap-3">
              <div className="flex flex-col">
                <div className="text-xs text-gray-500"> {t("date")} </div>
                <div className="placeholder:text-gray-300 py-1">
                  <input
                    type="datetime-local"
                    className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
                    value={transaction.date}
                    onChange={(e) => {
                      setTransaction({ ...transaction, date: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <div className="text-xs text-gray-500"> {t("name")} </div>
                <div className="placeholder:text-gray-300 py-1">
                  <input
                    type="text"
                    maxLength={50}
                    className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
                    value={transaction.name}
                    onChange={(e) => {
                      setTransaction({ ...transaction, name: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <div className="text-xs text-gray-500"> {t("account")} </div>
                <div className="placeholder:text-gray-300 py-1">
                  <Select
                    options={pettyCashAccount ? [...accountOptions, pettyCashAccount] : accountOptions}
                    value={selectedAccount}
                    onChange={(e) => setSelectedAccount({ ...e })}
                    placeholder={t("account")}
                    getLabel={(account) => <AccountOption account={account} />}
                    width="w-full"
                    nullable={false}
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <div className="text-xs text-gray-500">
                  {t("amount")}
                  <span>{` ( ${currencyToStr(transaction.currency)} )`}</span>
                </div>
                <div className="placeholder:text-gray-300 py-1">
                  <input
                    type="number"
                    className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
                    max={0}
                    value={transaction.amount}
                    onChange={(e) => setTransaction({ ...transaction, amount: e.target.value })}
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <label htmlFor="filteredCategories" className="text-xs text-gray-500">
                  {t("category")}
                  <Link
                    target="_blank"
                    to="/copilot"
                    className="text-gray-700 font-thin text-[10px] underline ml-1"
                    onClick={() => Mixpanel.track(MIXPANEL_EVENTS.btn_learn_more_accounting_codes_create_transaction)}>
                    ({t("learn_more")})
                  </Link>
                </label>
                <div className="placeholder:text-gray-300 py-1">
                  <Combobox
                    value={filteredCategories.find((category) => category.id == transaction.category)}
                    options={filteredCategories}
                    onChange={(category) => setTransaction({ ...transaction, category: category?.id || null })}
                    getLabel={(e) => (e ? e.id + " - " + t(`transactions.${e.id}`) : null)}
                    placeholder={t("transactions.edit.placeholder.select_category")}
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <label htmlFor="taxOptions" className="text-xs text-gray-500">
                  {t("vat")}
                </label>
                <div className="placeholder:text-gray-300 py-1">
                  <Combobox
                    options={taxOptions}
                    value={taxOptions.find((value) => value._id === transaction.TaxId)}
                    onChange={(value) => setTransaction({ ...transaction, TaxId: value._id || null })}
                    getLabel={(tax) => tax.name}
                    placeholder={t("vat")}
                    listHeader={
                      <button className="px-3 py-2 bg-black-100 text-white w-full flex items-center justify-between font-semibold text-xs" onClick={navigateToOrgSettings}>
                        {t("add_vat")} <span>+</span>
                      </button>
                    }
                  />
                </div>
              </div>
            </div>
            <hr className="my-4" />
            <div className="grid grid-cols-2 gap-3">
              <div className="flex flex-col">
                <div className="text-xs text-gray-500 "> {t("type")} </div>
                <div className="border-gray-300 placeholder:text-gray-300 py-1">
                  <Select
                    options={["CLIENT", "SUPPLIER"]}
                    value={transaction.type ? transaction.type : transaction.amount < 0 ? "SUPPLIER" : "CLIENT"}
                    onChange={(e) => {
                      if (e === "CLIENT")
                        setTransaction((transaction) => ({
                          ...transaction,
                          type: e,
                          SupplierId: null,
                        }));
                      else if (e === "SUPPLIER")
                        setTransaction((transaction) => ({
                          ...transaction,
                          type: e,
                          ClientId: null,
                        }));
                    }}
                    getLabel={(e) => t(e.toLowerCase())}
                    placeholder="Select a type"
                    nullable={false}
                  />
                </div>
              </div>
              <div className="flex flex-col">
                {transaction.type === "SUPPLIER" && (
                  <>
                    <div className="text-xs text-gray-500 "> {t("supplier")} </div>

                    <div className="border-gray-300 placeholder:text-gray-300 py-1">
                      <Combobox
                        options={supplierOptions}
                        value={supplierOptions.find((supplier) => supplier._id === transaction.SupplierId)}
                        onChange={(e) =>
                          setTransaction((transaction) => ({
                            ...transaction,
                            SupplierId: e?._id || null,
                            category: transaction.category || e?.defaultCategory,
                          }))
                        }
                        placeholder={t("supplier")}
                        getLabel={(supplier) => clientOrSupplierOption(supplier) + (supplier.similarCount > 0 ? " (" + t("suggested") + ")" : "")}
                        nullable={true}
                        listHeader={
                          <button className="px-3 py-2 w-full flex items-center justify-between font-semibold text-sm" onClick={() => showCreateSupplierModal()}>
                            {t("supplier.create_supplier")} <span>+</span>
                          </button>
                        }
                        displayValue={(supplier) => clientOrSupplierOption(supplier)}
                      />
                    </div>
                  </>
                )}
                {transaction.type === "CLIENT" && (
                  <>
                    <div className="text-xs text-gray-500 "> {t("client")} </div>
                    <div className="border-gray-300 placeholder:text-gray-300 py-1">
                      <Combobox
                        options={clientOptions}
                        value={clientOptions.find((client) => client._id === transaction.ClientId)}
                        onChange={(e) => {
                          setTransaction((transaction) => ({
                            ...transaction,
                            ClientId: e?._id || null,
                            category: transaction.category || e?.defaultCategory,
                          }));
                        }}
                        placeholder={t("client")}
                        getLabel={(client) => clientOrSupplierOption(client) + (client.similarCount > 0 ? " (" + t("suggested") + ")" : "")}
                        listHeader={
                          <button className="px-3 py-2 w-full flex items-center justify-between font-semibold text-sm" onClick={() => setShowCreateClientModal()}>
                            {t("clients.create_a_client")} <span>+</span>
                          </button>
                        }
                        displayValue={(client) => clientOrSupplierOption(client)}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>

            {transaction.amount > 0 ? (
              <div className="flex flex-col">
                <div className="text-xs text-gray-500 "> {t("sales_invoices")} </div>
                <div className="border-gray-300 placeholder:text-gray-300 py-1">
                  <TableAssociatedInvoices transaction={transaction} setTransaction={setTransaction} />
                </div>
              </div>
            ) : (
              <div className="flex flex-col">
                <div className="text-xs text-gray-500 "> {t("purchase_invoices")} </div>

                <div className="border-gray-300 placeholder:text-gray-300 py-1">
                  <TableAssociatedPurchaseInvoices transaction={transaction} setTransaction={setTransaction} />
                </div>
              </div>
            )}
            <hr className="mb-2" />

            <div className="flex flex-col">
              <div className="text-xs text-gray-500"> {t("memo_note")} </div>
              <textarea
                type="text"
                value={transaction.memo ?? ""}
                onChange={(e) => setTransaction({ ...transaction, memo: e.target.value })}
                className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg px-3 py-2 mt-1"
                placeholder={t("memo_note.transaction.placeholder")}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end w-full mt-3 border-t py-3 px-8">
          <div className="flex gap-2">
            <button
              disabled={!canCreate()}
              className="btn-primary w-max"
              onClick={(e) => {
                Mixpanel.track(MIXPANEL_EVENTS.btn_transaction_created);
                handleSave(e);
              }}
              data-tooltip-id={`tooltip-create`}
              data-tooltip-content={canCreate() ? "" : t("transaction.create_disable")}>
              {t("transactions.create_transaction")}
            </button>
            <button
              disabled={!canReconcile()}
              className="btn-primary w-max"
              onClick={(e) => {
                Mixpanel.track(MIXPANEL_EVENTS.btn_transaction_created_reconciled);
                handleSaveAndReconcile(e);
              }}
              data-tooltip-id={`tooltip-reconcile`}
              data-tooltip-content={canReconcile() ? "" : t("transaction.reconcile_disable")}>
              {t("transactions.create_reconcile")}
            </button>
            <Tooltip id={`tooltip-create`} opacity={1} style={{ zIndex: 1000, backgroundColor: "#40a5c6", borderRadius: "6px" }} />
            <Tooltip id={`tooltip-reconcile`} place={"top-end"} opacity={1} style={{ zIndex: 1000, backgroundColor: "#40a5c6", borderRadius: "6px" }} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

const TableAssociatedInvoices = ({ transaction, setTransaction }) => {
  const [loading, setLoading] = useState(false);
  const { organization } = useSelector((state) => state.Auth);
  const { t } = useTranslation();
  const [invoices, setInvoices] = useState([]);

  const getInvoices = async () => {
    const { data } = await api.post("/invoice/search", { per_page: 1000, statuses: ["SENT", "PAID", "DUE"], OrganizationId: organization?._id });
    setInvoices(data.invoices);
  };

  const removeInvoice = (index) => {
    setTransaction({
      ...transaction,
      Invoices: [...transaction.Invoices.slice(0, index), ...transaction.Invoices.slice(index + 1)],
    });
  };

  useEffect(() => {
    getInvoices();
  }, []);

  return (
    <div className="flex w-full">
      <div className="w-full align-middle overflow-visible  flex flex-col gap-3">
        <div className="flex flex-col gap-3">
          <div className="flex gap-3">
            <Select
              options={invoices}
              value={transaction?.Invoices || []}
              onChange={(selectedOptions) => {
                setTransaction({
                  ...transaction,
                  Invoices: selectedOptions,
                });
              }}
              multiple
              placeholder={t("invoice")}
              getLabel={(invoice) => (invoice ? invoice.reference + " " + (invoice.name || "") : null)}
              listHeader={
                <Link to={`/invoices?modal=open`} className="px-4 py-2 flex items-center justify-between font-semibold text-sm">
                  {t("invoices.create")} <span>+</span>
                </Link>
              }
              by={(a, b) => a._id === b._id}
            />
          </div>
          <div className="flex gap-3">
            <FileInput
              onChange={async (file) => {
                if (!file) return;
                setLoading(true);
                const { data } = await api.post("/invoice", {
                  reference: decodeURIComponent(file.target.value.split("/").pop().split(".").shift()),
                  status: "DUE",
                  source: "EXTERNAL",
                  file: file.target.value,
                });
                setLoading(false);

                toast.success(t("toast.sucess.created"));

                const test = setTransaction({
                  ...transaction,
                  Invoices: [data, ...transaction.Invoices],
                });
                console.log(test);
                getInvoices();
              }}
              name={transaction._id}
              folder="invoiceTransaction"
              width="w-full"
            />
            {loading && <Loader size="small" />}
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          {transaction.Invoices?.map((invoiceAssociated, index) => {
            return (
              <div key={invoiceAssociated._id} className="border rounded-lg p-4 relative">
                <div className="flex justify-between items-start mb-2">
                  <a href={invoiceAssociated.file} target="_blank" rel="noopener noreferrer" className="text-gray-500">
                    {invoiceAssociated.file && invoiceAssociated.file.indexOf(".pdf") !== -1 ? <FaRegFile size={18} /> : <AiFillFileImage size={18} />}
                  </a>
                  <button type="button" onClick={() => removeInvoice(index)} className="text-gray-500 hover:text-red-500">
                    <MdDeleteOutline size={20} />
                  </button>
                </div>
                <a href={invoiceAssociated.file} target="_blank" rel="noopener noreferrer" className="font-bold hover:underline">
                  {invoiceAssociated.reference}
                </a>
                <div className="text-xs text-gray-500">total: {invoiceAssociated.totalTaxIncluded || "-"}</div>
                {invoiceAssociated.createdAt && <div className="text-xs text-gray-500">created on {new Date(invoiceAssociated.createdAt).toLocaleDateString()}</div>}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const TableAssociatedPurchaseInvoices = ({ transaction, setTransaction }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [purchaseInvoices, setPurchaseInvoices] = useState([]);

  const getPurchaseInvoices = async () => {
    const { data } = await api.post("/purchase-invoice/search", { per_page: 1000 });
    setPurchaseInvoices(data.purchaseInvoices);
  };

  const removePurchaseInvoice = (index) => {
    setTransaction({
      ...transaction,
      PurchaseInvoices: [...transaction.PurchaseInvoices.slice(0, index), ...transaction.PurchaseInvoices.slice(index + 1)],
    });
  };

  useEffect(() => {
    getPurchaseInvoices();
  }, []);

  return (
    <div className="flex w-full gap-3">
      <div className="w-full align-middle overflow-visible  flex flex-col gap-3">
        <div className="flex flex-col gap-3">
          <div className="flex gap-3 ">
            <Select
              options={purchaseInvoices}
              value={transaction?.PurchaseInvoices || []}
              onChange={(selectedOptions) => {
                setTransaction({
                  ...transaction,
                  PurchaseInvoices: selectedOptions,
                });
              }}
              multiple
              placeholder={t("choose_invoice")}
              getLabel={(purchaseInvoice) => {
                const amount = purchaseInvoice.amount;
                let reference = purchaseInvoice.reference;
                let supplierName = purchaseInvoice.Supplier?.companyName ?? t("not_defined");
                let date;
                if (purchaseInvoice.issueDate) {
                  date = new Date(purchaseInvoice.issueDate);
                }
                return (
                  <div className="flex w-full gap-1">
                    <span className="flex-1">
                      {reference} - {supplierName}
                    </span>
                    <span className="px-2 bg-blue-100">{amount} €</span>
                    {date ? <span className="px-2 bg-green-100">{date.toLocaleDateString()}</span> : null}
                  </div>
                );
              }}
              by={(a, b) => a._id === b._id}
            />
          </div>
          <div className="flex gap-3">
            <FileInput
              onChange={async (file) => {
                if (!file) return;
                setLoading(true);
                const { data } = await api.post("/purchase-invoice", {
                  reference: decodeURIComponent(file.target.value.split("/").pop().split(".").shift()),
                  file: file.target.value,
                });
                setLoading(false);

                toast.success(t("toast.sucess.created"));

                setTransaction({
                  ...transaction,
                  PurchaseInvoices: transaction.PurchaseInvoices ? [data, ...transaction.PurchaseInvoices] : [data],
                });
                getPurchaseInvoices();
              }}
              name={transaction._id}
              folder="purchaseInvoiceTransaction"
              width="w-full"
            />
            {loading && <Loader size="small" />}
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          {transaction.PurchaseInvoices?.map((purchaseInvoiceAssociated, index) => {
            return (
              <div key={purchaseInvoiceAssociated._id} className="border rounded-lg p-4 relative">
                <div className="flex justify-between items-start mb-2">
                  <a href={purchaseInvoiceAssociated.file} target="_blank" rel="noopener noreferrer" className="text-gray-500">
                    {purchaseInvoiceAssociated.file && purchaseInvoiceAssociated.file.indexOf(".pdf") !== -1 ? <FaRegFile size={18} /> : <AiFillFileImage size={18} />}
                  </a>
                  <button type="button" onClick={() => removePurchaseInvoice(index)} className="text-gray-500 hover:text-red-500">
                    <MdDeleteOutline size={20} />
                  </button>
                </div>
                <a href={purchaseInvoiceAssociated.file} target="_blank" rel="noopener noreferrer" className="font-bold hover:underline">
                  {purchaseInvoiceAssociated.reference}
                </a>
                <div className="text-xs text-gray-500">total: {purchaseInvoiceAssociated.amount}</div>
                {purchaseInvoiceAssociated.issueDate && <div className="text-xs text-gray-500">issued on {new Date(purchaseInvoiceAssociated.issueDate).toLocaleDateString()}</div>}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
const AccountOption = ({ account }) => {
  const logo = account?.Requisition?.NordigenInstitution?.logo;
  if (!account) return;
  return (
    <span className="flex items-center">
      {logo ? <img src={logo} height={16} width={16} /> : <FaCashRegister size={16} />}
      <span className="ml-2">{getAccountName(account)}</span>
    </span>
  );
};
export default CreateTransaction;
