import categories from "@data/categories.js";
import api from "@services/api";
import { Mixpanel } from "@services/mixpanel";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { classNames, currencyToStr, getAccountName } from "@utils";
import CreateClient from "@components/modals/createClient";
import CreateEmployee from "@components/modals/createEmployee";
import CreateSupplier from "@components/modals/createSupplier";
import CreateTransaction from "@components/modals/createTransaction";
import { Combobox } from "@components/utils/Combobox";
import Loader from "@components/utils/Loader";
import Modal from "@components/utils/Modal";
import { SearchBar } from "@components/utils/SearchBar";
import { Select } from "@components/utils/Select";

import TransactionModal from "./editTransaction";
import { Pagination } from "./pagination";

import AllTransactions from "./allTransactions";
import TransactionsToReconcile from "./transactionsToReconcile";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";
import SupplierComboBox from "./components/SupplierComboBox";
import ClientComboBox from "./components/ClientComboBox";
import EmployeeComboBox from "./components/EmployeeComboBox";
import toast from "react-hot-toast";
import { HiOutlineSparkles } from "react-icons/hi2";
import { FaCashRegister } from "react-icons/fa6";

const ALL = "ALL";
const TO_RECONCILE = "TO_RECONCILE";

const List = () => {
  const { t } = useTranslation();
  const query = new URLSearchParams(useLocation().search);
  const [transactions, setTransaction] = useState([]);
  const [transactionsToReconcile, setTransactionsToReconcile] = useState([]);
  const [total, setTotal] = useState(0);
  const [totalToReconcile, setTotalToReconcile] = useState(0);
  const [filter, setFilter] = useState({ per_page: 20, page: 1, search: "", account: null, category: null, type: "" });
  const [accountOptions, setAccountOptions] = useState([]);
  const [clientOptions, setClientsOptions] = useState([]);
  const [supplierOptions, setSuppliersOptions] = useState([]);
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [open, setOpen] = useState(query.get("id"));
  const [openBankAccountToRenew, setOpenBankAccountToRenew] = useState(false);
  const navigate = useNavigate();
  const [tab, setTab] = useState(ALL);
  const [showCreateClient, setShowCreateClient] = useState(false);
  const [createdClient, setCreatedClient] = useState(null);
  const [showCreateSupplier, setShowCreateSupplier] = useState(false);
  const [createdSupplier, setCreatedSupplier] = useState(null);
  const [showCreateEmployee, setShowCreateEmployee] = useState(false);
  const [createdEmployee, setCreatedEmployee] = useState(null);
  const [showCreateTransaction, setShowCreateTransaction] = useState(false);
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [loadingTransaction, setLoadingTransaction] = useState(false);
  const [createdByMultiple, setCreatedByMultiple] = useState(false);
  const { organization } = useSelector((state) => state.Auth);

  const getTransactions = async () => {
    const { data } = await api.post("/transaction/search", {
      per_page: filter.per_page,
      page: filter.page,
      search: filter.search,
      type: filter.type,
      accountId: filter.account?._id,
      category: filter.category?.id,
      ClientId: filter.ClientId,
      SupplierId: filter.SupplierId,
      OrganizationId: organization?._id,
    });
    setTransaction(data.transactions);
    setTotal(data.total);
  };

  const getTransactionsToReconciled = async () => {
    const { data } = await api.post("/transaction/search", {
      per_page: filter.per_page,
      page: filter.page,
      search: filter.search,
      status: TO_RECONCILE,
      type: filter.type,
      accountId: filter.account?._id,
      category: filter.category?.id,
      ClientId: filter.ClientId,
      SupplierId: filter.SupplierId,
      OrganizationId: organization?._id,
      orderBy: "date",
      order: "DESC",
    });

    setTransactionsToReconcile(data.transactions);
    setTotalToReconcile(data.total);
  };

  useEffect(() => {
    if (!organization) return;
    const promises = [getTransactions(), getTransactionsToReconciled()];
    Promise.all(promises);
  }, [filter, organization]);

  const getAccounts = async () => {
    const responseAccount = await api.post("/account/search", { OrganizationId: organization?._id, includePettyCash: true });
    if (responseAccount?.data.find((account) => ["EXPIRED", "ERROR", "SUSPENDED"].includes(account.status))) {
      setOpenBankAccountToRenew(true);
    }
    setAccountOptions(responseAccount?.data);
  };

  useEffect(() => {
    if (!organization) return;
    getAccounts();
    Mixpanel.track(MIXPANEL_EVENTS.page_view_transactions);
  }, [organization]);

  useEffect(() => {
    if (!organization) return;
    const promises = [getClients(), getSuppliers(), getEmployees()];
    Promise.all(promises);
  }, [organization]);

  const getClients = async () => {
    const { data } = await api.post("/client/search", { per_page: 1000, OrganizationId: organization?._id });
    setClientsOptions(data.clients);
  };
  const getSuppliers = async () => {
    const { data } = await api.post("/supplier/search", { per_page: 1000, OrganizationId: organization?._id });
    setSuppliersOptions(data.suppliers);
  };
  const getEmployees = async () => {
    const { data: employeeRes } = await api.post(`/employee/search`, { per_page: 1000 });
    setEmployeeOptions(employeeRes.employees);
  };

  useEffect(() => {
    if (query.get("id")) setOpen(true);
  }, [query]);

  if (!transactions) return <Loader />;

  return (
    <div className="mx-auto">
      <TransactionModal
        isOpen={open}
        onClose={() => {
          navigate("/transactions");
          setOpen(false);
        }}
        onSave={() => {
          getTransactionsToReconciled();
          getTransactions();
        }}
        setShowCreateClientModal={() => {
          setShowCreateClient(true);
        }}
        setShowCreateEmployeeModal={() => {
          setShowCreateEmployee(true);
        }}
        createdEmployee={createdEmployee}
        createdClient={createdClient}
        showCreateSupplierModal={() => {
          setShowCreateSupplier(true);
        }}
        createdSupplier={createdSupplier}
        taxOptions={organization.Taxes}
      />
      <CreateTransaction
        isOpen={showCreateTransaction}
        onClose={() => setShowCreateTransaction(false)}
        onCreated={() => {
          getTransactions();
          getTransactionsToReconciled();
        }}
        onSave={() => {
          getTransactionsToReconciled();
          getTransactions();
        }}
        setShowCreateClientModal={() => {
          setShowCreateClient(true);
        }}
        createdClient={createdClient}
        showCreateSupplierModal={() => {
          setShowCreateSupplier(true);
        }}
        createdSupplier={createdSupplier}
        accountOptions={accountOptions}
        taxOptions={organization.Taxes}
      />
      <RenewAccountModal
        isOpen={openBankAccountToRenew}
        onClose={() => {
          setOpen(false);
          setOpenBankAccountToRenew(false);
        }}
      />
      <CreateClient
        open={showCreateClient}
        onClose={() => {
          setShowCreateClient(false);
        }}
        onCreated={(data) => {
          setCreatedClient({ ...data });
        }}
      />
      <CreateSupplier
        open={showCreateSupplier}
        onClose={() => {
          setShowCreateSupplier(false);
        }}
        onCreated={(data) => {
          setCreatedSupplier({ ...data });
        }}
      />
      <CreateEmployee
        open={showCreateEmployee}
        onClose={() => {
          setShowCreateEmployee(false);
        }}
        onCreated={(data) => {
          setCreatedEmployee({ ...data });
        }}
      />
      <div className="p-4">
        <div className="flex items-center justify-between p-2.5 mb-2 bg-gradient-to-r from-blue-500 to-purple-500 rounded-lg text-white">
          <span className="font-medium text-sm">{t("copilot.banner.title")}</span>
          <button
            onClick={() => {
              Mixpanel.track(MIXPANEL_EVENTS.btn_goto_copilot_from_transactions);
              navigate("/copilot");
            }}
            className="flex items-center gap-2 px-2.5 py-1 bg-white/20 rounded-full transition-all duration-300 hover:bg-white/30">
            <HiOutlineSparkles className="text-sm" />
            <span className="font-medium text-xs">{t("copilot.banner.button")}</span>

            <span className="text-xs bg-white/20 px-1.5 py-0.5 rounded-full">{t("copilot.banner.badge")}</span>
          </button>
        </div>

        <div className="flex flex-col">
          <div className="flex flex-col bg-white border border-gray-300 rounded-xl py-6 px-4">
            <div className="flex gap-3  justify-between">
              <div className="mb-5">
                <h1 className="text-2xl font-bold ">{t("transactions.title_page")}</h1>
                <p className="mt-2 text-slate-600">{t("transactions_intro")}</p>
              </div>
              <div>
                <button className="btn-primary focus:bg-blue float-right mb-2" onClick={() => setShowCreateTransaction(true)}>
                  {t("transactions.add_transaction")}
                </button>
              </div>
            </div>
            <div className="w-full flex flex-col gap-6">
              <div className="w-full">
                <label className="text-xs text-gray-500 capitalize">{t("search")}</label>
                <SearchBar search={filter.search} setFilter={setFilter} className="w-full text-lg" />
              </div>

              <div className="w-full grid grid-cols-1 lg:grid-cols-5 items-end gap-4">
                <div className="w-full">
                  <label className="text-xs font-medium text-gray-600 mb-1.5 block">{t("account")}</label>
                  <Select
                    options={accountOptions}
                    value={filter.account}
                    onChange={(e) => setFilter((f) => ({ ...f, account: e }))}
                    placeholder={t("account")}
                    getLabel={(account) => <AccountOption account={account} />}
                    width="w-full"
                    nullable={true}
                    className="shadow-sm"
                  />
                </div>

                <div className="w-full">
                  <label className="text-xs font-medium text-gray-600 mb-1.5 block">{t("clients")}</label>
                  <Combobox
                    options={clientOptions}
                    value={clientOptions.find((client) => client._id === filter?.ClientId)}
                    onChange={async (e) => {
                      setFilter((f) => ({ ...f, ClientId: e._id }));
                    }}
                    placeholder={t("client")}
                    getLabel={(client) => {
                      if (client.entityType === "COMPANY") return client?.companyName || "";
                      return `${client?.firstname || ""} ${client?.lastname || ""}`;
                    }}
                    displayValue={(client) => {
                      if (!client) return null;
                      if (client.entityType === "COMPANY") return client?.companyName;
                      return `${client?.firstname || ""} ${client?.lastname || ""}`;
                    }}
                    className="shadow-sm"
                  />
                </div>

                <div className="w-full">
                  <label className="text-xs font-medium text-gray-600 mb-1.5 block">{t("supplier")}</label>
                  <Combobox
                    options={supplierOptions}
                    value={supplierOptions.find((supplier) => supplier._id === filter?.SupplierId)}
                    onChange={async (e) => {
                      setFilter((f) => ({ ...f, SupplierId: e._id }));
                    }}
                    placeholder={t("supplier")}
                    getLabel={(supplier) => {
                      if (supplier.entityType === "COMPANY") return supplier?.companyName || "";
                      return `${supplier?.firstname || ""} ${supplier?.lastname || ""}`;
                    }}
                    displayValue={(supplier) => {
                      if (!supplier) return null;
                      if (supplier.entityType === "COMPANY") return supplier?.companyName;
                      return `${supplier?.firstname || ""} ${supplier?.lastname || ""}`;
                    }}
                    className="shadow-sm"
                  />
                </div>

                <div className="w-full">
                  <label className="text-xs font-medium text-gray-600 mb-1.5 block">{t("amount")}</label>
                  <Select
                    options={["INCOME", "EXPENSE"]}
                    value={filter.type}
                    onChange={(e) => setFilter((f) => ({ ...f, type: e }))}
                    placeholder={t("amount")}
                    getLabel={(type) => <span>{t(`transactions.${type}`)}</span>}
                    width="w-full"
                    nullable={true}
                    className="shadow-sm"
                  />
                </div>

                <div className="w-full">
                  <label className="text-xs font-medium text-gray-600 mb-1.5 block">
                    {t("categories")}
                    <Link
                      target="_blank"
                      to="/copilot"
                      className="text-gray-700 font-thin text-[10px] underline ml-1"
                      onClick={() => Mixpanel.track(MIXPANEL_EVENTS.btn_learn_more_accounting_codes_list_transactions)}>
                      ({t("learn_more")})
                    </Link>
                  </label>
                  <Select
                    options={categories}
                    value={filter.category}
                    onChange={(e) => setFilter((f) => ({ ...f, category: e }))}
                    placeholder={t("category")}
                    getLabel={(category) => (category ? category.id + " - " + t(`transactions.${category.id}`) : null)}
                    width="w-full"
                    nullable={true}
                    className="shadow-sm"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="sticky top-0 w-full bg-[#f5f6fc] z-10">
            <nav className="flex flex-row w-full justify-between gap-8 items-center my-2">
              <div className="flex p-2 w-fit gap-4 bg-white rounded-xl border border-gray-300">
                <TabItem
                  title={t("all")}
                  tab={ALL}
                  setTab={setTab}
                  active={tab === ALL}
                  RightComponent={<span className="ml-2 text-sm bg-blue-200 px-2 py-0.5 rounded-xl text-blue-500 border border-blue-500 font-medium">{total}</span>}
                />
                <TabItem
                  title={t("to_reconcile")}
                  tab={TO_RECONCILE}
                  setTab={setTab}
                  active={tab === TO_RECONCILE}
                  RightComponent={
                    <span className="ml-2 text-sm bg-orange-200 px-2 py-0.5 rounded-xl text-orange-500 border border-orange-500 font-medium">{totalToReconcile}</span>
                  }
                />
              </div>
              {selectedTransactions.length > 0 && tab === TO_RECONCILE && (
                <MultipleSelectionActions
                  createdByMultiple={createdByMultiple}
                  selectedTransactions={selectedTransactions}
                  setSelectedTransactions={setSelectedTransactions}
                  supplierOptions={supplierOptions}
                  clientOptions={clientOptions}
                  employeeOptions={employeeOptions}
                  setShowCreateSupplier={setShowCreateSupplier}
                  setShowCreateClient={setShowCreateClient}
                  setShowCreateEmployee={setShowCreateEmployee}
                  setLoadingTransaction={setLoadingTransaction}
                  onSave={() => {
                    getTransactionsToReconciled();
                    getTransactions();
                  }}
                />
              )}
            </nav>
          </div>

          <div className="w-full rounded-xl border border-gray-300 bg-white py-2">
            {tab === ALL && (
              <>
                <AllTransactions transactions={transactions} />
                <Pagination total={total} filter={filter} setFilter={setFilter} />
              </>
            )}
            {tab === TO_RECONCILE && (
              <>
                <TransactionsToReconcile
                  createdByMultiple={createdByMultiple}
                  setCreatedByMultiple={setCreatedByMultiple}
                  selectedTransactions={selectedTransactions}
                  setSelectedTransactions={setSelectedTransactions}
                  transactions={transactionsToReconcile}
                  loadingTransaction={loadingTransaction}
                  setLoadingTransaction={setLoadingTransaction}
                  onSave={() => {
                    getTransactionsToReconciled();
                    getTransactions();
                  }}
                />
                <Pagination total={totalToReconcile} filter={filter} setFilter={setFilter} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const MultipleSelectionActions = ({
  setCreatedByMultiple,
  supplierOptions,
  clientOptions,
  employeeOptions,
  setShowCreateSupplier,
  setShowCreateClient,
  setShowCreateEmployee,
  selectedTransactions,
  setSelectedTransactions,
  setLoadingTransaction,
  onSave,
}) => {
  const { t } = useTranslation();
  const updateMultipleTransactions = async ({ params, needTransactionsUpdate = false, successMessage = "toast.sucess.updated_sucessfully" }) => {
    setLoadingTransaction(true);
    try {
      const { ok } = await api.post(`/transaction/multi-action/`, {
        transactionsIds: selectedTransactions.map((transaction) => transaction._id),
        ...params,
      });
      if (!ok) {
        return;
      }
      if (needTransactionsUpdate) {
        const updatedSelectedTransactions = selectedTransactions.map((transactionToUpdate) => ({
          ...transactionToUpdate,
          ...params,
        }));
        setSelectedTransactions(updatedSelectedTransactions);
      }
      toast.success(t(successMessage));
      onSave();
    } catch (error) {
      console.log("error multiple update", error);
      toast.error(error.code);
    } finally {
      setLoadingTransaction(false);
    }
  };

  const updateMultipleTransactionsAndReconcile = async (e) => {
    try {
      if (!window.confirm("Are you sure you want to reconcile these transactions?")) return;
      e.preventDefault();
      updateMultipleTransactions({ params: { status: "RECONCILED" }, needTransactionsUpdate: true, successMessage: "toast.success.reconciled" });
    } catch (e) {
      console.log("error multiple reconcile", e);
      toast.error(e.code);
    }
  };

  const getSelectedCategory = () => {
    return selectedTransactions.every((transaction) => transaction.category === selectedTransactions[0].category)
      ? categories.find((category) => category.id == selectedTransactions[0].category)
      : null;
  };

  const canReconcileMultiple = () => {
    return selectedTransactions.every((transaction) => {
      if (!transaction.category || !transaction.type) {
        return false;
      }
      switch (transaction.type) {
        case "CLIENT":
          return transaction.ClientId;
        case "SUPPLIER":
          return transaction.SupplierId;
        case "EMPLOYEE":
          return transaction.EmployeeId;
        case "TRANSFER":
          return transaction.TransferAccountId;
        default:
          return false;
      }
    });
  };

  return (
    <div className="sticky top-0 bg-[#f5f6fc] z-10">
      <div className="flex justify-between items-center p-2 gap-4 bg-white rounded-xl border border-gray-300">
        <div className={classNames("p-1 text-center text-sm font-medium transition-all duration-200 bg-white rounded-xl border-2 border-white")}>
          <div className="flex items-center justify-center gap-2">
            <span className="ml-2 text-sm bg-blue-200 px-2 py-0.5 rounded-xl text-blue-500 border border-blue-500 font-medium">{selectedTransactions.length}</span>
            {t("transactions_selected")}
          </div>
        </div>
        <div className={`text-sm w-40`}>
          <Select
            options={["CLIENT", "SUPPLIER", "EMPLOYEE", "TRANSFER"]}
            value={selectedTransactions.every((transaction) => transaction.type === selectedTransactions[0].type) ? selectedTransactions[0].type : null}
            onChange={async (type) => {
              updateMultipleTransactions({ params: { type: type }, needTransactionsUpdate: true });
            }}
            getLabel={(e) => t(e.toLowerCase())}
            placeholder={t("type")}
            nullable={false}
          />
        </div>
        <div className={`text-sm`}>
          {selectedTransactions[0].type === "SUPPLIER" ? (
            <SupplierComboBox
              onUpdateTransaction={updateMultipleTransactions}
              setCreatedByMultiple={setCreatedByMultiple}
              supplierOptions={supplierOptions}
              setShowCreateSupplier={setShowCreateSupplier}
              selectedTransactions={selectedTransactions}
              setSelectedTransactions={setSelectedTransactions}
              width="w-96"
            />
          ) : selectedTransactions[0].type === "CLIENT" ? (
            <ClientComboBox
              onUpdateTransaction={updateMultipleTransactions}
              setCreatedByMultiple={setCreatedByMultiple}
              clientOptions={clientOptions}
              setShowCreateClient={setShowCreateClient}
              selectedTransactions={selectedTransactions}
              setSelectedTransactions={setSelectedTransactions}
              width="w-96"
            />
          ) : selectedTransactions[0].type === "EMPLOYEE" ? (
            <EmployeeComboBox
              onUpdateTransaction={updateMultipleTransactions}
              setCreatedByMultiple={setCreatedByMultiple}
              employeeOptions={employeeOptions}
              setShowCreateEmployee={setShowCreateEmployee}
              selectedTransactions={selectedTransactions}
              setSelectedTransactions={setSelectedTransactions}
              width="w-96"
            />
          ) : null}
        </div>
        <div className={`text-sm`}>
          <Combobox
            value={getSelectedCategory()}
            options={categories}
            onChange={async (category) => {
              updateMultipleTransactions({ params: { category: category?.id || null }, needTransactionsUpdate: true });
            }}
            getLabel={(e) => (e ? e.id + " - " + t(`transactions.${e.id}`) : null)}
            displayValue={(e) => {
              if (!e) return null;
              return t(`transactions.${e.id}`);
            }}
            placeholder={t("category")}
          />
        </div>
        <div className={`text-sm`}>
          <button
            className="px-2 font-semibold text-xs   bg-black text-white w-max h-8 rounded-lg disabled:opacity-50  "
            onClick={(e) => {
              updateMultipleTransactionsAndReconcile(e);
              Mixpanel.track(MIXPANEL_EVENTS.btn_reconcile_transactions);
            }}
            disabled={!canReconcileMultiple()}>
            {t("reconcile_all")}
          </button>
        </div>
      </div>
    </div>
  );
};

const TabItem = ({ tab, title, setTab, active, RightComponent }) => {
  return (
    <button
      onClick={() => setTab(tab)}
      className={classNames(
        "flex-1 p-1 w-44 text-center text-sm font-medium transition-all duration-200 rounded-xl",
        active ? "text-blue-500 border-2 border-blue-500" : "text-gray-500 border-2 hover:border-blue-200 border-gray-200",
      )}>
      <div className="flex items-center justify-center gap-2">
        {title}
        {RightComponent}
      </div>
    </button>
  );
};

const AccountOption = ({ account }) => {
  const logo = account?.Requisition?.NordigenInstitution?.logo;
  if (!account) return;
  return (
    <span className="flex">
      {logo ? <img src={logo} height={16} width={16} /> : <FaCashRegister size={16} />}
      <span className="ml-2">{getAccountName(account)}</span>
      <span className="ml-1">
        {account.amount} {currencyToStr(account.currency)}
      </span>
    </span>
  );
};

const RenewAccountModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Modal isOpen={isOpen} onClose={onClose} innerClassName="flex max-w-[40rem] w-[40vw] max-h-[calc(100vh-5rem)]">
      <div className="flex flex-col items-center justify-center w-full p-5">
        <div className="mb-5">{t("myintegrations.need_reconnection")}</div>
        <button
          className="btn-primary w-max"
          onClick={(e) => {
            Mixpanel.track(MIXPANEL_EVENTS.btn_renew_account_requisition);
            navigate("/bank");
          }}>
          {t("myintegrations.renew_account")}
        </button>
      </div>
    </Modal>
  );
};

export default List;
