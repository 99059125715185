import { useEffect, useState } from "react";
import api from "@services/api";
import toast from "react-hot-toast";
import Loader from "@components/utils/Loader";
import Modal from "@components/utils/Modal";
import { useTranslation } from "react-i18next";
import { Mixpanel } from "../../services/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";
import { RxCross1 } from "react-icons/rx";
import { LoaderIcon } from "react-hot-toast";

const Edit = ({ employee, setEmployee, onSave }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState();

  useEffect(() => {
    setValues({ ...employee });
  }, [employee]);

  if (!employee || !values) return <div />;

  async function handleSubmit(e) {
    try {
      e.preventDefault();
      setLoading(true);
      const { ok, code } = await api.put(`/employee/${employee._id}`, { ...values });
      if (!ok) throw { code };
      toast.success(t("toast.sucess.updated_sucessfully"));
      setEmployee(null);
      onSave();
    } catch (e) {
      console.error(t("toast.error"), e);
      toast.error(e.code);
    }
    setLoading(false);
  }

  if (loading) return <Loader />;

  return (
    <Modal isOpen={true} onClose={() => setEmployee(null)} innerClassName="flex max-w-[40rem] w-[90vw] max-h-[calc(100vh-5rem)]">
      <div className="w-full max-h-full flex flex-col overflow-y-hidden transform rounded-lg bg-white text-left align-middle shadow-xl transition-all">
        <div className="px-8 pt-8 pb-3 border-b flex justify-between">
          <h2 className="text-2xl font-semibold text-gray-800">{t("employees.edit")}</h2>
          <button type="submit" onClick={() => setEmployee(null)}>
            <RxCross1 />
          </button>
        </div>

        <div className="p-6 overflow-auto">
          <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
            <div className="grid grid-cols-2 grid-rows-1 gap-6">
              <div className="flex flex-col row-start-1">
                <div className="text-xs text-gray-500 pb-1">{t("first_name")}</div>
                <input
                  required
                  type="text"
                  value={values.firstname || ""}
                  onChange={(e) => setValues({ ...values, firstname: e.target.value })}
                  className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
                  placeholder={t("employees.edit.first_name_placeholder")}
                />
              </div>
              <div className="flex flex-col row-start-1">
                <div className="text-xs text-gray-500 pb-1">{t("last_name")}</div>
                <input
                  required
                  type="text"
                  value={values.lastname || ""}
                  onChange={(e) => setValues({ ...values, lastname: e.target.value })}
                  className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
                  placeholder={t("employees.edit.last_name_placeholder")}
                />
              </div>
            </div>

            <div className="grid grid-cols-1 grid-rows-1 gap-4">
              <div className="flex flex-col">
                <div className="text-xs text-gray-500 pb-1">{t("email")}</div>
                <input
                  required
                  type="text"
                  value={values.email || ""}
                  onChange={(e) => setValues({ ...values, email: e.target.value })}
                  className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
                  placeholder={t("employees.edit.email_placeholder")}
                />
              </div>
            </div>

            <div className="flex justify-end mt-4 gap-6">
              <button type="submit" className="btn-primary" onClick={() => Mixpanel.track(MIXPANEL_EVENTS.btn_save_employee)}>
                {t("save")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default Edit;
